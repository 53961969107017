@import '../components/Variables.scss';
@import '../components/Mixin.scss';

body .header--sidebar-opened + .MuiDrawer-docked .MuiDrawer-paper {
  z-index: 1206;
  overflow: visible;
}

body .header--sidebar-opened + .MuiDrawer-root + .wrapper {
  width: 100%;
}

body {
  font-family: $fontFamily;
  margin: 0;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .MuiTypography-root {
    font-family: $fontFamily;
  }
  .MuiDialog-paperWidthXs {
    max-width: 600px;
  }
  .icon-text {
    display: inline-flex;
    svg {
      margin-right: 6px;
      cursor: pointer;
    }
  }
  .ml-0 {
    margin-left: 0;
  }
  .ml-1 {
    margin-left: 1em !important;
  }
  .ml-10 {
    margin-left: 15px;
  }
  .mb-1 {
    margin-bottom: 1em;
  }
  .mr-1 {
    margin-right: 1em !important;
  }
  .mt-0 {
    margin-top: 0 !important;
  }
  .pb-0 {
    padding-bottom: 0 !important;
  }
  .pb-2 {
    padding-bottom: 1em !important;
  }
  .pt-0 {
    padding-top: 0 !important;
  }
  .pl-0 {
    padding-left: 0 !important;
  }
  .mt-1 {
    margin-top: 0.5em;
  }
  .mt-1 {
    margin-top: 1em !important;
  }
  .mt-2 {
    margin-top: 2em !important;
  }
  .mb-2 {
    margin-bottom: 1em;
  }
  .error-fs {
    @include fontSize-lineHeight(14, 20);
  }
  .small-font {
    @include fontSize-lineHeight(15, 18);
    color: $textGreyDarker;
    strong {
      color: $darkBlue;
    }
  }
  .panel__header--badge {
    justify-content: flex-start;
    align-items: center;
    .badge {
      display: inline-flex;
      margin-left: 10px;
      background: #e5f6fd;
      color: #014361;
      font-weight: 400;
      border-color: #d6e8ef;
      @include fontSize-lineHeight(14, 20);
    }
  }
  .modal__footer {
    &.modal__footer--flex {
      display: flex;
      justify-content: space-between;
      .footer__flex {
        display: flex;
        .primary-btn {
          background: $previousBtn;
          color: $previousBtnColor;
          &:hover {
            background: $nextBtn;
            color: $white;
          }
        }
        .cancel-btn {
          background: $nextBtn;
          color: $white;
        }
      }
    }
  }
  .disp-flex {
    display: flex;
  }
  .equip-assign-date {
    min-width: 120px;
  }
  .return-equip-detail-link {
    font-weight: bold;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .inner-content__modal-subheading {
    color: $headingColor;
    @include fontSize-lineHeight(20, 30);
    font-weight: 700;
    margin: 0 0 15px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 6px;
  }
  .d-flex {
    width: calc(100% - 65px);
    margin-left: 65px;
    position: relative;
    min-height: 100vh;
    padding-bottom: 50px;
    .MuiCollapse-vertical {
      .MuiListItemButton-root {
        padding-left: 21px;
      }
    }
    .error-page-container {
      height: calc(100vh - 60px);
      .error-page {
        background: #fff;
      }
    }
  }
  .MuiTooltip-tooltip {
    background: $black;
    color: $white;
    .MuiTooltip-arrow {
      color: $black;
    }
  }
  .visible-mobile {
    display: none;
  }
  .hide-mobile {
    display: block;
  }
  .text-uppercase {
    text-transform: uppercase;
  }

  .overlay-outer {
    display: flex;
    min-height: 100vh;
    position: relative;
    padding-bottom: 50px;
    .error-page-container {
      height: calc(100vh - 60px);
    }
  }

  .overpaid-amt {
    color: $colorDanger !important;
  }

  .amount-input-style {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .MuiDrawer-paper {
    background: $darkBlue;
    color: $white;

    .MuiListItemIcon-root {
      color: $white;
    }

    .sidebar-closebtn {
      width: 25px;
      height: 25px;
      background: $white;
      border: 1px solid $white;
      left: 21px;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);

      .MuiSvgIcon-root {
        width: 16px;
        color: $darkBlue;
      }

      &:hover {
        background: $darkBlue;

        .MuiSvgIcon-root {
          color: $white;
        }
      }
    }
  }

  .header {
    background: $white;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.02), 0px 3px 5px rgba(0, 0, 0, 0.1),
      0px 3px 10px rgba(0, 0, 0, 0.05);
    color: $darkBlue;

    .header__head-inner {
      justify-content: space-between;

      .head-inner__r-col {
        display: flex;
        align-items: center;
        .MuiButtonBase-root {
          &:hover {
            background: none;
          }
        }
        .r-col__prf-wrap {
          padding: 0;
          .prf-wrap__im {
            border: 1px solid $lightGrey;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            img {
              border-radius: 50%;
            }
          }
          .prf-wrap__name {
            padding-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
          .name-user {
            @include fontSize-lineHeight(15, 20);
            font-weight: 400;
            color: $darkGrey;
          }
          .name-store {
            @include fontSize-lineHeight(15, 20);
            font-weight: 400;
            color: $textMediumGrey;
            display: flex;
            align-items: center;
          }
        }
      }

      &.header__head-inner--flex-end {
        justify-content: flex-end;
      }
    }
  }

  .store-info-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid lightBlue;
    border-radius: 5px;
    padding: 8px 6px;
  }

  .custom-select-width {
    .heading-outer__rgt-col {
      .MuiOutlinedInput-root {
        width: 210px;
        min-width: auto;
      }
    }
  }

  .profile-menu {
    .MuiMenu-list {
      padding: 14px 15px;

      h5 {
        margin: 0 0 6px;
        border-bottom: 1px solid $borderLighterColor;
        text-transform: uppercase;
        font-family: $fontFamily;
        color: $textPrimary;
      }

      .MuiListItemIcon-root {
        min-width: 30px;
        color: $textGreyDarker;
      }

      .MuiMenuItem-root {
        padding: 6px 10px 6px 6px;
        font-family: $fontFamily;
        color: $textGreyDarker;

        &.active {
          color: $inputOutlinedFocus;
          background: rgba(0, 0, 0, 0.04);

          .MuiListItemIcon-root {
            color: $inputOutlinedFocus;
          }
        }
      }
    }

    .MuiPaper-elevation {
      &.MuiPaper-rounded {
        min-width: 215px;
      }
    }
  }

  .spacer-2 {
    margin-top: 0.5em !important;
  }

  .sidebar-logo {
    max-width: 130px;
    margin: 11px 17px 5px 0;
  }

  .lock-btn {
    background: $lightBlue;
    color: $white;
    margin: 3.8em 0.9em 0;
    text-transform: capitalize;
    @include fontSize-lineHeight(15, 23);
    font-family: $fontFamily;
    font-weight: 700;
    border: 1px solid $lightBlue;

    .MuiSvgIcon-root {
      font-size: 16px;
      margin-right: 5px;
    }

    &:hover {
      border-color: $white;
    }
  }

  .sidebar-list {
    margin-top: 10px;
    .MuiListItem-root {
      &.Mui-selected {
        background: $sidebarLinkBg;
        color: $sidebarLinkHoverColor;

        .MuiListItemIcon-root {
          color: $sidebarLinkHoverColor;
        }
      }
    }

    .MuiListItemButton-root {
      &:hover {
        background: $sidebarLinkBg;
        color: $sidebarLinkHoverColor;

        .MuiListItemIcon-root {
          color: $sidebarLinkHoverColor;
        }
      }
    }
  }

  .profile {
    margin: auto;
    max-width: 1500px;

    .profile__prf-im {
      display: flex;
      justify-content: flex-start;
      padding-right: 80px;
      max-width: 280px;
      flex-direction: column;
      position: relative;
      align-items: center;
      .prf-im__wrap {
        border: 1px solid $lightGrey;
        border-radius: 10px;
        display: flex;
        width: 100%;
        min-width: 200px;
        min-height: 200px;
        max-height: 200px;
        max-width: 200px;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          max-width: 100%;
          padding: 0;
          width: 100%;
          border-radius: 10px;
        }
      }

      .default-im {
        @extend img;
      }
      .del-btn {
        border: 1px solid $btnCancelBorder;
        color: $btnCancelColor;
        border-radius: 3px;
        @include fontSize-lineHeight(14, 20);
        text-transform: capitalize;
        font-weight: normal;
        padding: 4px 11px 4px 7px;
        max-width: 120px;
        margin-top: 12px;
        &:hover {
          background: $white;
          color: $btnPrimary;
          border-color: $btnPrimary;
        }
      }
    }

    .profile__prf-form {
      max-width: calc(100% - 280px);
      flex-basis: calc(100% - 280px);

      h2 {
        border-bottom: 1px solid $lighterGrey;
        padding-bottom: 10px;
        margin-bottom: 30px;
        color: $headingColor;
        @include fontSize-lineHeight(24, 30);
        font-weight: 700;

        span {
          color: $headingInnerColor;
          @include fontSize-lineHeight(16, 19);
          font-weight: 700;
        }
      }
    }

    .browse {
      position: absolute;
      bottom: 46px;
      right: 81px;
      width: 30px;
      height: 30px;
      border-radius: 4px 4px 10px;
      background: $darkGrey;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      input[type='file'] {
        opacity: 0;
        width: 30px;
        z-index: 2;
        height: 30px;
        cursor: pointer;
        position: absolute;
      }

      input[type='file']::-webkit-file-upload-button {
        /* chromes and blink button */
        cursor: pointer;
      }

      .browse__ic {
        color: $white;
        z-index: 1;
        cursor: pointer;
        font-size: 1.1rem;
      }
    }
  }

  .label {
    margin: 0 0 0.25em;
    @include fontSize-lineHeight(18, 28);
    display: inline-flex;
    color: $darkGrey;
    align-items: center;
    .label__text-info {
      @include fontSize-lineHeight(15, 20);
      color: #5a6266;
      margin-top: 2px;
    }
    span {
      color: $inputRequired;
      display: inline-block;
      margin-left: 0.25em;
    }

    &.label--width {
      width: 100%;
    }
    &.label--radio-wrap {
      display: flex;
      justify-content: space-between;
      .MuiFormGroup-root {
        flex-direction: row;
        label {
          display: flex;
          span {
            display: flex;
            color: $darkGrey;
            svg {
              color: $inputOutlinedFocus;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .MuiButtonBase-root {
          padding: 0;
        }
      }
    }
  }

  .MuiOutlinedInput-input {
    padding: 13px 14px;
    &.Mui-disabled {
      background: $inputDisabledBg;
      border-color: $inputDisabledBorder;
    }
  }

  .btn-wrap {
    border-top: 1px solid $lightGreyBoder;
    padding-top: 15px;
    margin-top: 40px;
    &.btn-wrap--align-right {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
    &.btn-wrap--mt-sm {
      margin-top: 15px;
      padding-top: 10px;
    }
  }

  .primary-btn {
    background: $btnPrimary;
    color: $white;
    font-family: $fontFamily;
    border-radius: 3px;
    @include fontSize-lineHeight(16, 24);
    text-transform: none;
    font-weight: normal;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 8px 15px;
    &.primary-btn--svg {
      svg {
        margin-right: 5px;
      }
    }
    &.primary-btn--secondary-color {
      background-color: $summerSeason;
      &:hover {
        background-color: $summerSeasonHover;
      }
    }
    &.primary-btn--sm {
      padding: 3px 5px;
      @include fontSize-lineHeight(14, 20);
      min-width: 50px;
    }
    &:hover {
      background: $darkBlue;
    }

    &.Mui-disabled {
      background: $lighterGrey;
      color: $tableHeaderColor;
    }
  }

  .btn-loader {
    color: $btnPrimary;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
  }

  .cancel-btn {
    border: 1px solid $btnCancelBorder;
    color: $btnCancelColor;
    border-radius: 3px;
    font-family: $fontFamily;
    @include fontSize-lineHeight(16, 24);
    text-transform: capitalize;
    font-weight: normal;
    margin-left: 18px;
    padding: 8px 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    &:hover {
      background: $white;
      color: $btnPrimary;
      border-color: $btnPrimary;
    }
  }

  .prev-btn {
    background: $prevBtnBg;
    color: $prevBtnColor;
    border-radius: 3px;
    font-family: $fontFamily;
    @include fontSize-lineHeight(16, 24);
    text-transform: none;
    font-weight: normal;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 8px 15px;

    &:hover {
      color: $prevBtnBg;
      background: $prevBtnColor;
    }

    &.Mui-disabled {
      background: $lighterGrey;
      color: $tableHeaderColor;
      opacity: 0.8;
    }
  }
  .filter-delete {
    background: $btnDelete;
    color: $white;
    padding: 8px 15px;
    margin-left: 10px;
    min-width: 160px;
    max-height: 40px;
    &:hover {
      background: $btnDeleteHover;
    }
  }
  .delete-btn {
    background: $btnDelete;
    color: $white;
    border-radius: 3px;
    font-family: $fontFamily;
    @include fontSize-lineHeight(16, 24);
    text-transform: capitalize;
    font-weight: normal;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 8px 15px;

    &:hover {
      background: $btnDeleteHover;
    }
  }

  .ok-btn {
    background: $btnPrimary;
    color: $white;
    border-radius: 3px;
    font-family: $fontFamily;
    @include fontSize-lineHeight(16, 24);
    text-transform: capitalize;
    font-weight: normal;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 8px 15px;

    &:hover {
      background: $darkBlue;
    }
  }
  .back-btn {
    background: $backBtnBg;
    color: $white;
    border-radius: 3px;
    font-family: $fontFamily;
    @include fontSize-lineHeight(16, 24);
    text-transform: capitalize;
    font-weight: normal;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 5px 15px;
    &.Mui-disabled {
      color: $white;
      opacity: 0.6;
    }
    &:hover {
      background: $backBtnBgHover;
    }
  }

  //this will adjust the position of status with heading.
  .inventory-heading_status {
    display: flex;
    .badge {
      margin-left: 10px;
    }
  }

  .darkblue-btn {
    background: $darkBlueBtn;
    color: $white;
    border-radius: 3px;
    font-family: $fontFamily;
    @include fontSize-lineHeight(16, 24);
    text-transform: capitalize;
    font-weight: normal;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 8px 15px;

    &:hover {
      background: $darkBlue;
    }
    &.Mui-disabled {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .MuiMenuItem-root {
    font-family: $fontFamily;
    color: $textGreyDarker;
    @include fontSize-lineHeight(15, 22);

    svg {
      margin-right: 7px;
    }
  }

  .textarea {
    font-family: $fontFamily;
    @include fontSize-lineHeight(16, 24);
    height: auto !important;
    resize: none;
    width: 100%;
    padding: 10px 14px;
    border: 1px solid $inputBorder;
    border-radius: 3px;
  }

  .react-datepicker-popper[data-placement^='bottom'] {
    padding-top: 0;
  }

  .react-datepicker-popper[data-placement='bottom-end'] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement='top-end'] .react-datepicker__triangle {
    left: unset !important;
    right: 18px;
    transform: none !important;
    z-index: 1;
  }

  .widget {
    padding-left: 20px;
    border-left: 1px solid $inputBorder;
    display: flex;
    flex-direction: column;
    height: 100%;

    .widget__row {
      margin-bottom: 15px;

      .MuiFormControl-root {
        width: 100%;

        input {
          width: 100%;
          padding: 10px 14px;
          border-radius: 3px;
          background: none;
        }
      }

      .row__heading {
        font-family: $fontFamily;
        @include fontSize-lineHeight(20, 30);
        border-bottom: 1px solid $inputBorder;
        font-weight: bold;
      }

      input[type='file'] {
        width: 100%;
      }
    }

    .widget__notes {
      color: $textMediumGrey;
      @include fontSize-lineHeight(16, 24);
    }
  }

  .wrapper {
    .wrapper__inner-content {
      margin: auto;

      h2 {
        border-bottom: 1px solid $lighterGrey;
        padding-bottom: 10px;
        margin-bottom: 20px;
        color: $headingColor;
        @include fontSize-lineHeight(24, 30);
        font-weight: 700;
      }

      .inner-content__heading-outer {
        .widget__col-btn-grp {
          display: flex;
        }
        &.inner-content__heading-outer--return-items {
          .filter-wrap {
            display: flex;
          }
          .filter-first-col {
            display: flex;
          }
        }
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 1em 0;
        align-items: flex-end;
        .heading-outer__text {
          font-size: 14px;
          line-height: 20px;
          margin-left: 10px;
        }
        .heading-outer__season {
          font-size: 15px;
          line-height: 22px;
          display: inline-flex;
          margin-right: 15px;
        }
        &.inner-content__heading-outer--flex-wrap {
          flex-wrap: wrap;
          .heading-outer__flex-box {
            margin-bottom: 5px;
            padding-right: 10px;
          }
        }
        &.inner-content__heading-outer--lg-content {
          .heading-outer__rgt-col {
            .rgt-col__widget {
              label + .MuiInputBase-root {
                margin-right: 15px;
              }
              label + .MuiInputBase-root + label + .MuiInputBase-root {
                margin-right: 0;
              }
            }
          }
          &.inner-content__heading-outer--lg-content-large {
            .heading-outer__rgt-col {
              .rgt-col__widget {
                label + .MuiInputBase-root {
                  margin-right: 0;
                }
                label + .MuiInputBase-root + label + .MuiInputBase-root {
                  margin-right: 0;
                }
                .widget__col {
                  margin-right: 15px;
                  display: flex;
                  &:last-child {
                    .MuiTextField-root {
                      margin: 0 0 0 15px;
                    }
                  }
                  .rdrDay {
                    font-family: $fontFamily;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
        &.inner-content__heading-outer--mt {
          margin-top: 1.8em;
        }
        &.inner-content__heading-outer--pt {
          padding-top: 2em;
        }
        &.inner-content__heading-outer--border-btm {
          border-bottom: 1px solid $lightGreyBorder;
          padding-bottom: 6px;
          .heading-outer__rgt-col--emp {
            flex-direction: row;
            .primary-btn {
              margin-left: 15px;
            }
            .MuiTextField-root {
              margin: 0 0 0 15px;
            }
            .rgt-col__widget {
              margin: 0;
            }
          }
          .heading-outer__rgt-col--return {
            flex-direction: row;
            align-items: flex-end;
            .rgt-col__widget {
              margin: 0;
            }
          }
        }

        &.inner-content__heading-outer--mb-0 {
          margin-bottom: 0;
          .heading-outer__rgt-col {
            .MuiTextField-root {
              margin: 0;
            }
          }
        }

        h1 {
          color: $headingColor;
          @include fontSize-lineHeight(24, 30);
          font-weight: 700;
          margin: 0;
        }

        h3 {
          color: $headingColor;
          @include fontSize-lineHeight(20, 30);
          font-weight: 700;
          margin: 0;

          span {
            @include fontSize-lineHeight(16, 22);
            font-weight: 400;
            color: $textGreyDarker;
          }
        }
        .heading-outer__btn-wrap {
          .delete-btn {
            margin-right: 15px;
          }
          .MuiButton-root {
            svg {
              margin-right: 5px;
            }
          }
          &.heading-outer__btn-wrap--ml {
            .primary-btn {
              &:first-child {
                margin-left: 0;
              }
              margin-left: 15px;
            }
          }
        }
        .heading-outer__rgt-col {
          display: flex;
          &.heading-outer__rgt-col--customer {
            .MuiTextField-root {
              margin: 0 0 0 15px;
            }
          }
          .rgt-col__widget--wd {
            .MuiAutocomplete-root {
              margin: 0;
              min-width: 360px;
            }
          }
          .MuiTextField-root {
            margin: 0 15px;
            .MuiOutlinedInput-root {
              min-width: 230px;
              min-height: 40px;
              padding-right: 8px;
            }
          }
          .MuiAutocomplete-root {
            margin: 0 15px;
          }

          .MuiAutocomplete-inputRoot {
            padding: 3px 10px;
          }

          .MuiOutlinedInput-root {
            min-width: 230px;
            min-height: 40px;
          }

          .MuiOutlinedInput-input {
            padding: 5px 14px;
          }

          .primary-btn {
            margin-left: 15px;
          }

          .rgt-col__widget {
            display: flex;
            &.rgt-col__widget--mr-0 {
              .MuiTextField-root {
                margin-right: 0;
              }
            }
          }
        }
      }
      // to align the search items to the right.
      .reservation-right-align {
        justify-content: flex-end;
      }

      .inner-content__table-wrap {
        box-shadow: 0 2px 8px rgb(0, 0, 0, 0.3);
        border-radius: 10px;

        .table-wrap__table {
          &.table-wrap__table--trans-history {
            thead {
              tr {
                th {
                  &:first-child {
                    width: 110px;
                  }
                }
              }
            }
            tbody {
              tr {
                td {
                  &:first-child {
                    text-align: right;
                  }
                  .MuiFormControlLabel-root {
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                  }
                }
              }
            }
          }
          thead {
            tr {
              th {
                background: $tableHeaderBg;
                color: $tableHeaderColor;
                @include fontSize-lineHeight(15, 22);
                font-weight: 700;
                font-family: $fontFamily;

                &:first-child {
                  border-radius: 10px 0 0 0;
                }

                &:last-child {
                  border-radius: 0 10px 0 0;
                }

                .MuiTableSortLabel-root {
                  &.Mui-active {
                    color: $darkGrey;
                  }
                }
              }
            }
          }

          tbody {
            tr {
              td {
                &.MuiTableCell-root {
                  padding: 10px 15px;
                  border-bottom: 1px solid $borderLighterColor;
                  font-family: $fontFamily;
                  @include fontSize-lineHeight(15, 22);
                  font-weight: 500;
                  color: $tableCellColor;
                }
                .MuiFormControlLabel-root {
                  margin: 0;
                  .MuiButtonBase-root {
                    padding-top: 0;
                    padding-bottom: 0;
                  }
                  .MuiRadio-root {
                    &:hover {
                      background: transparent;
                    }
                  }
                }
                &:first-child {
                  padding-left: 20px;
                }

                &:last-child {
                  padding-right: 20px;
                }
                .tdcell_action {
                  cursor: pointer;
                  background: transparent;
                  border: none;
                  &:disabled {
                    opacity: 0.5;
                  }
                }
                .react-tel-input {
                  .phone-input {
                    padding: 0;
                    width: 146px;
                    height: auto;
                  }
                }
              }
            }
          }
          &.table-wrap__table--report {
            tbody {
              tr {
                td {
                  padding-top: 15px;
                  padding-bottom: 15px;
                }
              }
            }
          }
          &.table-wrap__table--addon {
            thead {
              tr {
                th {
                  &:first-child {
                    padding-left: 35px;
                  }
                }
              }
            }

            tbody {
              tr {
                td {
                  &:first-child {
                    padding-left: 35px;
                  }

                  a {
                    color: $linkColor;

                    &:hover {
                      color: $linkColor;
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
          &.table-wrap__table--reservation {
            thead {
              tr {
                th {
                  &:first-child {
                    padding-left: 35px;
                  }
                }
              }
            }
            tbody {
              tr {
                td {
                  &:first-child {
                    padding-left: 30px;
                    min-width: 240px;
                  }

                  a {
                    color: $linkColor;

                    &:hover {
                      color: $linkColor;
                      text-decoration: none;
                    }
                  }
                }
              }
            }
          }
          &.table-wrap__table--trans-detail {
            tbody {
              tr {
                td {
                  &:nth-child(4) {
                    width: 25%;
                  }
                }
              }
            }
          }
          tbody {
            tr {
              &.reservation-pending {
                background: $warningBgColor;
              }
              &.reservation-cancelled {
                background: #fdeded;
              }
              &.reservation-completed {
                background: #edf7ed;
              }
            }
          }
        }

        .MuiTablePagination-toolbar {
          min-height: 62px;
          padding: 0 20px;
          display: block;
          float: left;
          width: 100%;
        }

        .MuiInputBase-colorPrimary {
          margin: 14px 0 0 10px;
          border: 1px solid $borderLighterColor;
          min-width: 25px;
        }

        &.inner-content__table-wrap--logs {
          .table-wrap__table {
            tr {
              th {
                &:first-child {
                  padding-left: 25px;
                }
                &:nth-child(2) {
                  width: 10%;
                }
                &:nth-child(3) {
                  width: 20%;
                }
                &:nth-child(4) {
                  width: 30%;
                }
                &:nth-child(5) {
                  width: 30%;
                }

                &:last-child {
                  width: 10%;
                }
              }

              td {
                &:first-child {
                  width: 20%;
                  padding-left: 25px;
                }

                &:nth-child(2) {
                  width: 10%;
                }
                &:nth-child(3) {
                  width: 20%;
                }
                &:nth-child(4) {
                  width: 30%;
                }
                &:nth-child(5) {
                  width: 30%;
                }

                &:last-child {
                  width: 10%;
                  padding-right: 30px;
                }
              }
            }
          }
        }
        &.inner-content__table-wrap--notes {
          .table-wrap__table {
            tr {
              th {
                &:first-child {
                  padding-left: 25px;
                }
              }

              td {
                &:first-child {
                  width: 10%;
                  padding-left: 25px;
                }

                &:nth-child(2) {
                  width: 20%;
                }

                &:nth-child(3) {
                  width: 45%;
                }
                &:nth-child(4) {
                  width: 15%;
                }

                &:last-child {
                  width: 10%;
                  padding-right: 30px;
                }
              }
            }
          }
        }
        &.inner-content__table-wrap--rented-detail {
          margin-bottom: 30px;
          margin-top: 10px;
          table {
            tr {
              th {
                &:last-child {
                  label {
                    width: 180px;
                  }

                  padding-right: 10px;
                  padding-left: 10px;
                }
              }
              td {
                &:last-child {
                  label {
                    width: 180px;
                  }
                  padding-right: 27px;
                }
                .MuiInputBase-colorPrimary {
                  min-width: 150px;
                  margin: 0;
                  .MuiOutlinedInput-input {
                    padding-right: 0;
                  }
                  .MuiInputAdornment-root {
                    margin-left: 0;
                  }
                }
                &.return-date-and-time {
                  min-width: 152px;
                }
              }
            }
          }
        }
        &.inner-content__table-wrap--space {
          margin-bottom: 30px;
          margin-top: 10px;
          table {
            tr {
              th {
                &:last-child {
                  label {
                    width: 220px;
                  }

                  padding-right: 10px;
                  padding-left: 10px;
                }
              }
              td {
                &:last-child {
                  label {
                    width: 220px;
                  }
                  padding-right: 27px;
                }
                .MuiInputBase-colorPrimary {
                  min-width: 200px;
                  margin: 0;
                }
              }
            }
          }
        }
      }

      .MuiTablePagination-selectLabel {
        float: left;
        margin: 22px 0 0 0;
        font-family: $fontFamily;
      }

      .MuiTablePagination-spacer {
        flex: none;
      }

      .MuiTablePagination-displayedRows {
        float: right;
        right: 105px;
        position: relative;
        margin: 20px 0 15px;
        width: 125px;
        text-align: right;
      }

      .MuiTablePagination-actions {
        float: right;
        margin: 10px 0;
        position: relative;
        right: -125px;

        .MuiIconButton-root {
          background: $paginationArrowBg;
          margin: 0 3px;
          transition: opacity 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

          &:hover {
            opacity: 0.7;
          }
        }
      }
      .link-btn {
        display: inline-flex;
        align-items: center;
        @include fontSize-lineHeight(16, 22);
        color: $textGreyDarker;
        font-weight: 700;
        text-transform: capitalize;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        svg {
          margin-right: 0.3em;
          color: $textGreyDarker;
        }
      }
      &.wrapper__inner-content--tabs-padding-sm {
        .tabs-panel {
          .tabs-panel__inner {
            padding: 22px 30px;
            .MuiGrid-root {
              .MuiGrid-item {
                padding-top: 18px;
                .modal-row {
                  .modal-row__desc {
                    margin-top: 1px;
                  }
                }
              }
            }
          }
        }
      }
    }
    &.wrapper--modal {
      display: flex;
      padding: 0 4px;
      .wrapper__inner-content {
        .inner-content__table-wrap {
          &.inner-content__table-wrap--space {
            margin: 2em 0;
            tr {
              td {
                &:first-child {
                  width: 20%;
                }
                &:nth-child(2) {
                  width: 20%;
                }
              }
            }
          }
          .MuiInputBase-colorPrimary {
            margin: 0;
            max-width: 170px;
          }
        }
      }
    }
    .accordion-content {
      .accordion-content__inner {
        .panel {
          min-height: auto;
        }
      }
    }
  }

  .MuiDialogContent-root {
    &.MuiDialogContent-dividers {
      padding: 16px 3px;
      margin: 0 24px;
      border-bottom: none;
    }
  }
  .MuiAutocomplete-hasPopupIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root,
  .MuiAutocomplete-hasClearIcon.css-1qqsdnr-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding-right: 15px;
  }
}
.item-retured-no {
  background: $lightRedBg;
  &:hover {
    background: $lightRedBg !important;
  }
  td {
    border-color: #f5f5f5 !important;
  }
  .badge {
    &.badge--red {
      background: $lightRedBadgeBg;
    }
  }
  .item-retured-no {
    background: $lightRedBg;
    .badge--red {
      background: $lightRedBadgeBg;
    }
  }
}

.btn-wrap {
  border-top: 1px solid $lightGreyBoder;
  padding-top: 15px;
  margin-top: 40px;
}

.checkbox-wrap {
  .MuiFormControlLabel-root {
    margin-right: 25px;
  }
}

.tab-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  label {
    font-weight: 700;
    @include fontSize-lineHeight(17, 25);
  }
  .MuiTabs-root {
    padding: 7px 5px 0;

    .MuiTabs-indicator {
      background: $white;
    }

    .MuiTabs-scroller {
      overflow: visible !important;
    }
  }

  .MuiTab-root {
    background: $tabBgColor;
    color: $tabHeaderColor;
    font-family: $fontFamily;
    font-weight: 700;
    margin-right: 1em;
    border-radius: 6px 6px 0 0;
    text-transform: capitalize;
    @include fontSize-lineHeight(20, 30);
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.3);
    min-width: 150px;
    min-height: 60px;

    &.Mui-selected {
      background: $white;
      color: $darkBlue;
      box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.2);
    }
  }
}

.accordion-panel {
  &.accordion-panel--padding-sm {
    .MuiAccordion-root {
      box-shadow: none;
      border: none;
      width: 100%;

      &::before {
        background-color: transparent;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .MuiAccordionDetails-root {
        padding: 22px 20px 18px;
      }

      .MuiAccordionSummary-root {
        background: $darkBlue;
        color: $white;
        border-radius: 8px;
        margin-bottom: 0;

        &.Mui-expanded {
          min-height: auto;
          border-radius: 8px 8px 0 0;
        }

        .MuiAccordionSummary-content {
          &.Mui-expanded {
            margin: 12px 0;
          }
        }

        .MuiTypography-root {
          font-weight: 700;
          display: flex;
          align-items: center;
        }

        .MuiAccordionSummary-expandIconWrapper {
          svg {
            fill: $white;
          }
        }
      }

      &.Mui-expanded {
        border: 1px solid $borderLighterColor;
        background: $white;
        margin: 0 0 25px;
        border-radius: 0 0 8px 8px;
      }

      .MuiAccordionDetails-root {
        padding: 30px 35px;
      }

      margin-bottom: 25px;
    }
    .MuiFormControlLabel-root {
      margin-bottom: 8px;
      margin-right: 0;
    }
    .inner-content__table-wrap {
      margin-bottom: 10px;
    }
    .panel__content-row {
      background: $white;
      .MuiGrid-grid-md-7 {
        padding-right: 10px;
      }
      &:nth-child(odd) {
        background: $white;
      }
    }
  }
  .MuiAccordion-root {
    box-shadow: none;
    border: none;
    width: 100%;

    &::before {
      background-color: transparent;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .MuiAccordionSummary-root {
      background: $darkBlue;
      color: $white;
      border-radius: 8px;
      margin-bottom: 0;

      &.Mui-expanded {
        min-height: auto;
        border-radius: 8px 8px 0 0;
      }

      .MuiAccordionSummary-content {
        &.Mui-expanded {
          margin: 12px 0;
        }
      }

      .MuiTypography-root {
        font-weight: 700;
      }
      .MuiTypography-root {
        font-weight: 700;
        display: flex;
        align-items: center;
      }

      .MuiAccordionSummary-expandIconWrapper {
        svg {
          fill: $white;
        }
      }
    }

    &.Mui-expanded {
      border: 1px solid $borderLighterColor;
      background: $white;
      margin: 0 0 25px;
      border-radius: 0 0 8px 8px;
    }

    .MuiAccordionDetails-root {
      padding: 30px 35px;
    }

    margin-bottom: 25px;
  }
}

.tabs-panel {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  width: 100%;
  margin: 0 5px;
  border-radius: 0 0 10px 10px;

  .tabs-panel__inner {
    padding: 24px 40px;
    &.tabs-panel__inner--xs-padding {
      padding: 15px 10px 10px;
    }

    .inner-content__heading-outer {
      margin-top: 0.5em !important;
    }
  }

  .MuiAccordion-root {
    box-shadow: none;
    border: none;

    &::before {
      background-color: transparent;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .MuiAccordionSummary-root {
      background: $darkBlue;
      color: $white;
      border-radius: 8px;
      margin-bottom: 0;

      &.Mui-expanded {
        min-height: auto;
        border-radius: 8px 8px 0 0;
      }

      .MuiTypography-root {
        font-weight: 700;
      }

      .MuiAccordionSummary-content {
        &.Mui-expanded {
          margin: 12px 0;
        }
      }

      .MuiAccordionSummary-expandIconWrapper {
        svg {
          fill: $white;
        }
      }
    }

    &.Mui-expanded {
      border: 1px solid $borderLighterColor;
      background: $borderLighterColor;
      margin: 0 0 25px;
      border-radius: 8px;
    }

    .MuiAccordionDetails-root {
      padding: 30px 35px;
    }

    margin-bottom: 25px;
  }

  .panel {
    min-height: 200px;
    background: $white;
    border-radius: 10px;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    &.panel--column {
      flex-direction: column;
      margin-bottom: 2.3em;
      box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.2);
      min-height: auto;
      .MuiGrid-container {
        .MuiGrid-item {
          &:first-child {
            border-right: 2px solid $greyBlockBorder;
            padding-right: 4px;
          }
          &:last-child {
            padding-left: 4px;
          }
        }
      }
      .panel__header {
        .MuiGrid-item {
          flex-direction: column;
          display: flex;
          .header__edit {
            svg {
              color: $textGreyDarker;
              cursor: pointer;
            }
          }
        }
        &.panel__header--twocol {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          a {
            cursor: pointer;
            color: $linkColor;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .panel__content-row {
        display: flex;
        padding: 0.6em 1em;
        label {
          text-transform: uppercase;
          color: $labelHeadingColor;
          font-weight: 700;
          width: 45%;
        }
        a {
          color: $linkColor;
        }
        .content-row__col {
          display: inline-block;
          flex-wrap: wrap;
          max-width: 55%;
          .react-tel-input {
            .special-label {
              display: none;
            }
            .phone-input {
              padding: 0;
              height: auto;
              background: transparent;
              font-size: 1rem;
            }
          }
          .hide-mobile {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .visible-mobile {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        &:nth-child(even) {
          background: $rowBgColor;
        }
        &:nth-child(odd) {
          background: $white;
        }
      }
    }
    &.panel--single-col {
      margin-bottom: 0;
      .panel__content-row {
        &:nth-child(even) {
          background: $white;
        }
        &:nth-child(odd) {
          background: $rowBgColor;
        }
        &:last-child {
          border-radius: 0 0 5px 5px;
        }
      }
    }
    &.panel--stripe {
      .panel__content-row {
        &:nth-child(even) {
          background: $white;
        }
        &:nth-child(odd) {
          background: $rowBgColor;
        }
      }
    }
    &.panel--large-label {
      .panel__content-row {
        label {
          width: 50%;
        }
      }
    }
    .panel__lft {
      align-items: center;
      justify-content: center;
      display: flex;

      img {
        max-width: 100%;
        border-radius: 10px 0 0 10px;
      }
    }

    .panel__rgt {
      background: $panelBg;
      border-radius: 0 10px 10px 0;
      padding: 14px 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .rgt__heading {
        @include fontSize-lineHeight(14, 20);
        font-weight: 700;
        color: $darkBlue;
        margin-bottom: 1.5em;
      }

      .rgt__col-lft {
        @include fontSize-lineHeight(12, 18);
      }

      .rgt__col-rgt {
        @include fontSize-lineHeight(12, 14);
        padding-bottom: 4px;
      }

      .rgt__btn-group {
        margin-top: 2em;

        .primary-btn {
          padding: 4px 10px;
          margin-left: 5px;
          width: calc(100% - 5px);
          font-weight: 700;
          @include fontSize-lineHeight(12, 18);
        }

        .delete-btn {
          padding: 4px 10px;
          width: calc(100% - 5px);
          margin-right: 5px;
          font-weight: 700;
          @include fontSize-lineHeight(12, 18);
        }
      }
    }
  }
}
.modal {
  .tabs-panel {
    .panel {
      .panel__content-row {
        label {
          width: 55%;
        }
      }
      &.panel--addon {
        min-height: auto;
        .panel__content-row {
          label {
            width: 100%;
          }
        }
      }
    }
  }
}
.badge-seasonal {
  background: $badgeSeasonalBg;
  border: 1px solid $badgeSeasonalBorder;
  color: $badgeSeasonalColor;
  padding: 2px 5px;
  border-radius: 4px;
  @include fontSize-lineHeight(12, 18);
  margin-left: 4px;
}

.badge {
  padding: 7px 15px;
  border-radius: 4px;
  @include fontSize-lineHeight(12, 18);
  white-space: nowrap;
  &.badge--green {
    background: $badgeGreenBg;
    color: $badgeGreenColor;
  }
  &.badge--red {
    background: $badgeRedBg;
    color: $badgeRedColor;
  }
  &.badge--blue {
    background: $badgeBlueBg;
    color: $badgeBlueColor;
  }
  &.badge--purple {
    background: $badgePurpleBg;
    color: $badgePurpleColor;
  }
  &.badge--orange {
    background: $badgeYellowBg;
    color: $orangeColor;
  }
  &.badge--addon-selected-badge {
    background: $badgeYellowBg;
    color: $badgeYellowColor;
    display: inline-flex;
    margin-left: 10px;
  }
  &.badge--manual {
    background: #ffe4cf;
    color: #764923;
    margin-left: 14px;
  }
}

.pt-0 {
  padding-top: 0 !important;
}

.delete-ic {
  color: $btnDelete;
}

em {
  color: $placeholderColor;
}

.modal-content {
  padding: 40px 0 32px !important;

  .MuiGrid-root {
    .MuiGrid-item {
      padding-top: 10px;
    }
  }
}

.modal-row {
  display: flex;
  flex-direction: column;

  .modal-row__heading {
    @include fontSize-lineHeight(14, 22);
    font-weight: 700;
    font-family: $fontFamily;
    text-transform: uppercase;
    color: $labelHeadingColor;
  }

  .modal-row__desc {
    @include fontSize-lineHeight(16, 24);
    font-weight: 500;
    font-family: $fontFamily;
    color: $labelDescColor;
    background: $labelBg;
    padding: 7px 15px;
    min-height: 38px;
    border-left: 2px solid $labelBorderColor;
    margin-top: 4px;
  }
}

.bg-content {
  background: $labelBg;
  border: 1px solid $greyBlockBorder;
  padding: 10px 20px 18px;
  color: $darkBlue;
  border-radius: 3px;

  .content__heading {
    color: $headingColor;
    @include fontSize-lineHeight(20, 30);
    font-weight: 700;
    margin: 0;
    border-bottom: 1px solid $lightGreyBoder;
    margin-bottom: 0.6em;
  }
}

.content-row {
  display: flex;
  flex-direction: column;

  .content-row__heading {
    @include fontSize-lineHeight(14, 22);
    font-weight: 700;
    font-family: $fontFamily;
    text-transform: uppercase;
    color: $labelHeadingColor;
  }

  .content-row__desc {
    @include fontSize-lineHeight(16, 24);
    font-weight: 500;
    font-family: $fontFamily;
    color: $labelDescColor;
    background: $white;
    padding: 7px 15px;
    min-height: 38px;
    border-left: 3px solid $labelBorderColor;
    margin-top: 4px;
    word-break: break-all;
    .phone-input-display {
      .phone-input {
        border: none;
        padding: 0;
        cursor: default;
        width: 100%;
        background-color: transparent;
        height: auto;
      }
      .flag-dropdown {
        display: none;
      }
    }
  }
}

.accordion-headerrow {
  display: flex;

  p {
    margin-right: 35px;
  }
}

.deposit-row {
  color: $textGreyDarker;
  font-weight: 700;

  span {
    color: $darkBlue;
    font-weight: 700;
  }
}

.MuiPopover-paper {
  &.MuiPaper-root {
    width: auto;
    min-width: 8em;
  }
}
.loader {
  margin-top: 10px;

  .loader__dots {
    position: relative;
    width: 50px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;

    .dot {
      width: 10px;
      height: 10px;
      background: $darkBlue;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 15px);
    }

    .dot1 {
      left: 0px;
      -webkit-animation: dot-jump 0.5s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
      animation: dot-jump 0.5s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
    }

    .dot2 {
      left: 20px;
      -webkit-animation: dot-jump 0.5s 0.2s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
      animation: dot-jump 0.5s 0.2s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
    }

    .dot3 {
      left: 40px;
      -webkit-animation: dot-jump 0.5s 0.4s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
      animation: dot-jump 0.5s 0.4s cubic-bezier(0.77, 0.47, 0.64, 0.28) alternate infinite;
    }
  }

  .loader__text {
    display: inline-block;
    margin-left: 12px;
    vertical-align: top;
    color: $darkGrey;
  }
}

@-webkit-keyframes dot-jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes dot-jump {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}
.grey-bg {
  background: $greyBg;
  border-radius: 5px;
  padding: 20px 20px 28px;
  margin-top: 25px;
  label {
    &.MuiFormControlLabel-root {
      padding: 20px 20px 0;
      margin-right: 0;
    }
  }
}
.w-100 {
  width: 100%;
}

.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  background: $white;
  height: auto;
  padding: 120px 30px 30px;
  img {
    width: 200px;
  }
  .error-page__heading {
    @include fontSize-lineHeight(120, 125);
    color: $black;
    font-family: $fontFamily;
    font-weight: 700;
    margin: 10px 0 10px;
    text-align: center;
  }
  .error-page__sub-heading {
    @include fontSize-lineHeight(38, 45);
    color: $black;
    font-family: $fontFamily;
    font-weight: 700;
    margin-top: 10px;
    text-align: center;
  }
  p {
    @include fontSize-lineHeight(20, 35);
    color: $darkGrey;
    font-family: $fontFamily;
    margin: 20px 0 0;
    text-align: center;
    max-width: 700px;
  }
  .error-page__link {
    display: flex;
    justify-content: center;
    color: $white;
    background: $btnPrimary;
    text-decoration: none;
    align-items: center;
    margin-top: 35px;
    border-radius: 5px;
    @include fontSize-lineHeight(18, 22);
    font-weight: 400;
    padding: 13px 20px;
    display: inline-flex;
    svg {
      @include fontSize-lineHeight(18, 20);
      color: $white;
      margin: 0 5px 0 0;
    }
    &:hover {
      text-decoration: none;
    }
  }
}
.widget__row--thumbnail {
  display: flex;
  .row__img {
    margin-right: 20px;
    border: 1px solid $lightGrey;
    border-radius: 5px;
    min-width: 150px;
    max-width: 150px;
    min-height: 150px;
    max-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 6px;
    img {
      max-width: 100%;
    }
  }
}
.textarea-input {
  .MuiInputBase-root {
    padding: 0;
    min-height: 262px;
    max-height: 262px;
    overflow: auto;
    align-items: flex-start;
    textarea {
      height: 100% !important;
      overflow: visible !important;
      min-height: 232px;
    }
  }
  &.textarea-input--min-height {
    .MuiInputBase-root {
      min-height: 100px;
      max-height: 100px;
      textarea {
        height: 100% !important;
        overflow: visible !important;
        min-height: 74px;
      }
    }
  }
}
.ic-pos {
  margin-top: 10px;
  display: inline-flex;
  position: relative;
  top: -7px;
}
.img-pos {
  align-self: center;
}
.MuiSelect-select {
  &.MuiTablePagination-select {
    line-height: 1.4 !important;
  }
}
.MuiAlert-message {
  ul {
    margin: 0;
    padding: 0 0 0 15px;
  }
}
.snackbar-multiline {
  .MuiAlert-icon {
    display: none;
  }
}
.field-wrap__checkbox-mt {
  margin-top: 20px;
}
.MuiAutocomplete-listbox {
  padding: 0;
  li {
    @include fontSize-lineHeight(14, 20);
    border-bottom: 1px solid $lightGrey;
    &:last-child {
      border-bottom: none;
    }
  }
}
.custom-panel {
  background: $customPanelBg;
  border-radius: 5px;
  border: 1px solid $customPanelBorderColor;
  padding: 12px 18px 21px;
  .MuiTextField-root {
    background: $white;
  }
  .custom-panel__rgt {
    position: relative;
    padding-left: 30px !important;
    &:before {
      border-left: 1px solid $lightGreyBoder;
      content: '';
      position: absolute;
      height: 80%;
      top: 25%;
      left: 14px;
    }
    .label {
      &.label--sub-heading {
        color: $customPanelSubHeading;
        @include fontSize-lineHeight(16, 22);
        font-weight: bold;
        margin-bottom: 8px;
      }
    }
  }
  .modal-row__desc {
    background: $customPanelLabelBg;
    border-color: $customPanelLabelBorder;
  }
}
.cell-wrap {
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  svg {
    padding-right: 5px;
  }
  a {
    color: $linkColor;
  }
  .badge-seasonal {
    margin-left: 8px;
  }
  .cell-wrap__moon {
    color: $greyColor;
  }
  .cell-wrap__sun {
    color: $orangeColor;
  }
  .cell-wrap__text-ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 160px;
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
  }
}
.card {
  color: $white;
  padding: 20px 20px 15px;
  border-radius: 8px;
  margin-top: 40px;
  &.card--blue {
    background: $cardBgBlue;
  }
  &.card--dark-blue {
    background: $cardBgDarkBlue;
  }
  &.card--purple {
    background: $cardBgPurple;
  }
  .card__header {
    @include fontSize-lineHeight(22, 28);
    font-weight: 700;
    display: flex;
    align-items: center;
    .calendar-ic {
      @include fontSize-lineHeight(25, 27);
    }
    svg {
      margin-right: 8px;
      @include fontSize-lineHeight(21, 25);
    }
  }
  .card__body {
    @include fontSize-lineHeight(50, 55);
    font-weight: 900;
    text-align: center;
    margin: 6px 0 15px;
  }
  .card__footer {
    color: $white;
    display: flex;
    justify-content: space-between;
    @include fontSize-lineHeight(17, 22);
  }
}

.prwidget-rgt {
  padding-left: 80px;
  &.prwidget-rgt--reset-password {
    padding-left: 0;
    background: #f5f5f5;
    border: 1px solid #e2e2e2;
    padding: 5px 10px;
    margin: 5px 0 0 0;
    border-radius: 5px;
    .prwidget-rgt__heading {
      @include fontSize-lineHeight(14, 20);
      font-weight: 700;
      color: $textPrimary;
      margin: 0 0 0.2em;
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        @include fontSize-lineHeight(12, 17);
        max-width: 50%;
        flex: 0 0 50%;
        padding-left: 15px;
        padding-right: 10px;
        background-size: 9px;
        background-position: 0 4px;
        margin-bottom: 4px;
        &.tick {
          background-size: 11px;
          background-position: 0 6px;
        }
      }
    }
  }
  .prwidget-rgt__heading {
    @include fontSize-lineHeight(17, 24);
    font-weight: 700;
    color: $textPrimary;
    margin: 0 0 0.5em;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      @include fontSize-lineHeight(16, 27);
      color: $darkGrey;
      background: url('../assets/list.png');
      background-repeat: no-repeat;
      background-position: 0px 8px;
      padding-left: 25px;
      &.tick {
        background: url('../assets/list-tick.png');
        background-repeat: no-repeat;
        background-position: 0px 8px;
      }
    }
  }
}

.copyright-footer {
  display: flex;
  justify-content: center;
  color: $copyrightText;
  @include fontSize-lineHeight(15, 18);
  position: absolute;
  bottom: 11px;
  transform: translateX(-50%);
  left: 50%;
  align-items: center;
  margin: 0;
  min-height: 50px;
  width: 389px;
}
.overlay-outer {
  .copyright-footer {
    left: calc(50% + 85px);
  }
  .MuiCollapse-vertical {
    .MuiListItemButton-root {
      padding-left: 31px;
    }
  }
}
.text-capitalize {
  text-transform: capitalize;
}
.label-refund {
  @include fontSize-lineHeight(12, 18);
}
.phone-input-display {
  .phone-input {
    border: none;
    padding-left: 0;
    cursor: default;
    width: 100%;
    background-color: transparent;
  }
  .flag-dropdown {
    display: none;
  }
}
.react-tel-input {
  .phone-input {
    height: 49px;
    padding: 11px 14px 11px 54px;
    width: 100%;
    font-size: 16px;
    &::placeholder {
      opacity: 0.5;
      @include fontSize-lineHeight(16, 20);
    }
  }
  .flag-dropdown {
    padding: 0 3px;
  }
}
.mt-2 {
  margin-top: 0.7em !important;
}
.mt-3 {
  margin-top: 1.4em !important;
}
.transform-none {
  text-transform: none !important;
}
.flex-row {
  flex-direction: row !important;
}
.date-range {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  .range {
    padding: 6px 14px;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    background: none;
    height: 2.5rem;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: flex;
    min-width: 226px;
    width: 100%;
    font-size: 16px;
    border-radius: 3px;
    color: $darkGrey;
  }
  .rdrCalendarWrapper {
    border-radius: 8px 8px 0 0;
  }
  .date-range__actions {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5px;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0 15px;
    button {
      &:last-child {
        margin-left: 10px;
      }
    }
  }
  .date-range__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    z-index: 9999;
  }
  .date-range__inner {
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0 3px 8px rgb(0 0 0 / 20%);
    border-radius: 8px;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }
}
.pos-fixed {
  position: fixed;
  width: 100%;
}
.cell-info {
  .cell-info__waiver-icon {
    display: none;
  }
}
.disp-flex {
  display: flex;
}
.waiver-sign .primary-btn {
  padding: 0 !important;
  color: $linkColor;
  text-decoration: underline;
  background: transparent;
  box-shadow: none;
  margin-left: 15px;
  &:before {
    content: '|';
    margin-right: 15px;
  }
  &:hover {
    background: transparent;
    text-decoration: none;
  }
}
.heading-outer__badge {
  @include fontSize-lineHeight(15, 22);
  display: inline-flex;
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: none;
  border: 1px solid transparent;
  &.heading-outer__badge--progress {
    background: #e5f6fd;
    color: #014361;
    font-weight: 400;
    border-color: #d6e8ef;
  }
  &.heading-outer__badge--completed {
    background: #edf7ed;
    color: #1e4620;
    font-weight: 400;
    border-color: #d4e4d4;
  }
  &.heading-outer__badge--draft {
    background: #fff4e5;
    color: #663c00;
    font-weight: 400;
    border-color: #ecdfce;
  }
  &.heading-outer__badge--cancelled {
    background: #fdeded;
    color: #5f2120;
    font-weight: 400;
    border-color: #e3cccc;
  }
}
.custom-phone {
  display: flex;
  width: 100%;
  .MuiInputBase-root {
    width: 90px;
    margin-right: 10px;
  }
  input {
    width: calc(100% - 100px);
    padding: 13px 14px;
    border: 1px solid #c4c4c4;
    border-radius: 3px;
    &:hover {
      border-color: #000;
    }
  }
  .custom-phone__sel {
    .MuiOutlinedInput-input {
      padding-top: 8px !important;
      padding-bottom: 8px !important;
      padding-left: 10px;
      padding-right: 22px !important;
    }
    .con-code {
      @include fontSize-lineHeight(13, 16);
    }
    .con-code-no {
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 110%;
    }
  }
}
.thead-checkbox-wrap {
  text-align: center;
  label {
    margin: 0 0 0 5px;
  }
}
.custom-select-width {
  font-weight: bold;
  padding-bottom: 5px;
  text-align: left;
  width: 265px;
}
.custom-select-width + .MuiOutlinedInput-root {
  width: 270px;
}
.MuiSwitch-colorPrimary + .MuiSwitch-track {
  background-color: #1976d2;
}
.MuiSwitch-colorPrimary.Mui-checked.MuiButtonBase-root + .MuiSwitch-track {
  background-color: #e75017;
}
.MuiSwitch-colorPrimary.Mui-checked .MuiSwitch-thumb {
  background-color: #e75017;
}
.MuiSwitch-colorPrimary .MuiSwitch-thumb {
  background-color: #1976d2;
}
body
  .wrapper
  .wrapper__inner-content
  .inner-content__heading-outer.inner-content__heading-outer--inventory
  .heading-outer__rgt-col.heading-outer__rgt-col--inventory
  .rgt-col__widget
  .filter-delete {
  margin-right: 15px;
  margin-left: 0;
}
.label-bg {
  background: #ebebeb;
  border-left: 3px solid #696969;
  padding: 10px;
  margin-top: 4px;
}
.feedback-label {
  text-transform: uppercase;
  color: #26343b;
  font-weight: 700;
}
.star-color {
  color: #d8b413;
}
@media screen and (min-width: 992px) {
  body .header--sidebar-opened + .MuiDrawer-root + .wrapper {
    width: calc(100% - 225px);
  }
}
@media screen and (max-width: 1620px) {
  .wrapper {
    .wrapper__inner-content {
      .inner-content__heading-outer {
        &.inner-content__heading-outer--return-items {
          .filter-wrap {
            .MuiTextField-root .MuiOutlinedInput-root {
              width: 100%;
            }
          }
          .heading-outer__rgt-col {
            .rgt-col__widget {
              flex-direction: column;
              justify-content: flex-end;
              align-items: flex-end;
              &.mr-1 {
                margin-right: 0 !important;
              }
            }
          }
          .filter-wrap {
            display: flex;
            margin-top: 10px;
            .rgt-col__widget {
              margin-left: 10px;
              &.first-child {
                margin-left: 0;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1810px) {
  body
    .wrapper
    .wrapper__inner-content
    .inner-content__heading-outer.inner-content__heading-outer--inventory
    .heading-outer__rgt-col.heading-outer__rgt-col--inventory
    .rgt-col__widget
    .filter-delete {
    margin-right: 0;
    margin-left: 8px;
  }
  body {
    .wrapper {
      .wrapper__inner-content {
        .inner-content__heading-outer {
          &.inner-content__heading-outer--inventory {
            .heading-outer__rgt-col {
              &.heading-outer__rgt-col--inventory {
                flex-direction: column-reverse;
                align-items: flex-end;
                .rgt-col__widget {
                  &:first-child {
                    margin-top: 10px;
                  }
                }
              }
            }
          }
          &.inner-content__heading-outer--lg-content {
            &.inner-content__heading-outer--lg-content-large {
              .heading-outer__rgt-col {
                flex-direction: column-reverse;
                align-items: flex-end;
                .rgt-col__widget {
                  .widget__col {
                    &:last-child {
                      margin-right: 0;
                    }
                  }
                  &:last-child {
                    margin-bottom: 10px;
                  }
                }
                .MuiTextField-root {
                  margin: 0 0 0 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .wrapper {
    .wrapper__inner-content {
      max-width: 1500px;
    }
  }
  .waiver-sign {
    display: flex;
    flex-direction: column;
    .primary-btn {
      margin-top: 5px;
      color: $linkColor;
      margin-left: 0;
      justify-content: flex-start;
      &:before {
        content: '';
        margin-right: 0;
      }
      &:hover {
        background: transparent;
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 1550px) {
  body {
    .wrapper {
      .accordion-content {
        .accordion-content__inner {
          flex-basis: 50%;
          max-width: 50%;
        }
      }

      .wrapper__inner-content {
        .inner-content__heading-outer {
          &.inner-content__heading-outer--lg-content {
            .heading-outer__rgt-col {
              .MuiOutlinedInput-input {
                padding: 5px 30px 5px 14px;
              }
            }
            &.inner-content__heading-outer--filter-clear {
              .heading-outer__rgt-col {
                .MuiOutlinedInput-root {
                  width: 175px;
                  min-width: auto;
                  max-height: 40px;
                }
              }
            }
          }
          &.inner-content__heading-outer--search-fullwidth {
            .heading-outer__rgt-col {
              .MuiOutlinedInput-root {
                width: auto;
                min-width: auto;
              }
            }
          }
          .heading-outer__rgt-col {
            .MuiOutlinedInput-root {
              width: 190px;
              min-width: auto;
            }
            .filter-delete {
              padding: 8px 9px;
              @include fontSize-lineHeight(15, 22);
            }
            .primary-btn {
              padding: 8px 9px;
              @include fontSize-lineHeight(15, 22);
            }

            .darkblue-btn {
              padding: 8px 9px;
              @include fontSize-lineHeight(15, 22);
            }
          }
          .heading-outer__rgt-col1 {
            .MuiOutlinedInput-root {
              width: 210px;
              min-width: auto;
            }
          }
        }
      }
    }
    .tabs-panel {
      .panel {
        &.panel--large-label {
          .panel__content-row {
            label {
              width: 58%;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1450px) {
  body {
    .wrapper {
      .wrapper__inner-content {
        .inner-content__heading-outer {
          &.inner-content__heading-outer--lg-content {
            .heading-outer__rgt-col {
              align-items: flex-end;
              flex-direction: column-reverse;
              .rgt-col__widget {
                &:first-child {
                  margin-bottom: 5px;
                }
              }
              .MuiTextField-root {
                margin-right: 0;
              }
            }
          }
        }
        .inner-content__table-wrap {
          &.inner-content__table-wrap--rented-detail {
            table {
              tr {
                th {
                  &:last-child {
                    label {
                      width: 171px;
                    }

                    padding-right: 10px;
                    padding-left: 10px;
                  }
                  &:nth-child(4) {
                    display: none;
                  }
                }
                td {
                  &:last-child {
                    label {
                      width: 170px;
                    }
                    padding-right: 27px;
                  }
                  .MuiInputBase-colorPrimary {
                    min-width: 160px;
                    margin: 0;
                  }
                  &:nth-child(4) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      .tabs-panel {
        .panel {
          &.panel--column {
            &.panel--large-label {
              .panel__content-row {
                padding: 0.6em 0.5em;
                label {
                  width: 64%;
                  @include fontSize-lineHeight(14, 20);
                }
                span {
                  @include fontSize-lineHeight(14, 20);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1400px) {
  body {
    .profile-menu {
      .MuiMenu-list {
        .MuiMenuItem-root {
          padding: 4px 10px 4px 6px;
        }
      }
    }

    .sidebar-logo {
      max-width: 134px;
      margin: 11px 17px 5px 0;
    }

    .sidebar-list {
      padding-top: 0;
      padding-bottom: 0;

      .MuiListItemButton-root {
        padding-top: 3px;
        padding-bottom: 3px;
        min-height: 42px;

        .MuiTypography-root {
          font-size: 0.95em;
        }

        .MuiListItemIcon-root {
          .MuiSvgIcon-root {
            font-size: 1.2em;
          }
        }
      }
    }

    .bg-content {
      .MuiGrid-item {
        max-width: 33.33%;
        flex-basis: 33.33%;
      }
    }
    .wrapper {
      .wrapper__inner-content {
        .inner-content__heading-outer {
          &.inner-content__heading-outer--return-items {
            .heading-outer__rgt-col {
              .rgt-col__widget {
                margin: 0;
                .filter-wrap {
                  .rgt-col__widget {
                    margin: 0 5px;
                  }
                  .MuiTextField-root .MuiOutlinedInput-root {
                    width: 100%;
                  }
                  .filter-delete {
                    margin-left: 5px;
                  }
                }
              }
            }
          }
          .heading-outer__rgt-col {
            .primary-btn {
              @include fontSize-lineHeight(14, 20);
              margin-left: 10px;
            }
            .filter-delete {
              @include fontSize-lineHeight(14, 20);
              max-height: 40px;
              min-width: 140px;
            }
            .darkblue-btn {
              @include fontSize-lineHeight(14, 20);
            }
            .rgt-col__widget {
              margin: 5px 0;
            }
            .MuiTextField-root {
              margin: 0 10px;
            }
          }
          h3 {
            @include fontSize-lineHeight(19, 28);
          }
        }
      }
      .tabs-panel {
        .tabs-panel__inner {
          padding: 24px 32px;
          &.tabs-panel__inner--xs-padding {
            padding: 15px 10px 10px;
          }
        }
        .panel {
          &.panel--column {
            .panel__content-row {
              label {
                width: 53%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  body {
    .wrapper {
      .wrapper__inner-content {
        .inner-content__heading-outer {
          &.inner-content__heading-outer--lg-btn {
            flex-direction: column;
            align-items: flex-start;
            h3 {
              margin: 0 0 10px;
            }
          }
          .heading-outer__rgt-col {
            flex-direction: column-reverse;
            align-items: flex-end;

            .MuiAutocomplete-root {
              margin-right: 0;
            }

            .rgt-col__widget {
              margin: 5px 0;
            }
            .MuiTextField-root {
              margin: 0 0 0 13px;
            }
            &.heading-outer__rgt-col--rented-items {
              flex-direction: row;
              flex-wrap: wrap;
              width: calc(100% - 190px);
              justify-content: flex-end;
            }
          }
          &.inner-content__heading-outer--lg-content {
            &.inner-content__heading-outer--lg-content-large {
              &.inner-content__heading-outer--filter-clear {
                .heading-outer__rgt-col {
                  .MuiTextField-root {
                    margin: 0;
                  }
                  .rgt-col__widget {
                    &:first-child {
                      flex-direction: column;
                      .widget__col {
                        &:last-child {
                          margin-top: 10px;
                        }
                      }
                    }
                  }
                }
              }
              flex-direction: column;
              align-items: flex-start;
              .heading-outer__rgt-col {
                flex-direction: column-reverse;
                align-items: flex-start;
                .rgt-col__widget {
                  &:first-child {
                    flex-direction: row;
                    .widget__col {
                      margin-bottom: 0;
                      margin-right: 15px;
                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  }
                  &:last-child {
                    margin-bottom: 10px;
                  }
                }
                .MuiTextField-root {
                  margin: 0 0 0 15px;
                }
              }
            }
          }
        }
      }
      .tab-header {
        .MuiTab-root {
          min-width: auto;
          @include fontSize-lineHeight(18, 24);
          min-width: 125px;
          min-height: 60px;
        }
      }
      &.wrapper--cms {
        .label {
          @include fontSize-lineHeight(16, 22);
        }
        .MuiTypography-root {
          @include fontSize-lineHeight(15, 22);
        }
      }
    }
    .card {
      .card__header {
        @include fontSize-lineHeight(20, 28);
      }
    }
    .overlay-outer {
      .accordion-panel--padding-sm {
        .panel {
          .panel__content-row {
            flex-direction: column;
            .MuiGrid-grid-md-5 {
              max-width: 100%;
              flex-basis: 100%;
            }
            .MuiGrid-grid-md-7 {
              max-width: 100%;
              flex-basis: 100%;
            }
          }
        }
      }
    }
  }
  .widget__row--thumbnail {
    display: flex;
    flex-direction: column;
    .row__img {
      margin-bottom: 15px;
    }
  }
}
.cell-info {
  .cell-info__waiver-icon {
    display: none;
  }
}
@media screen and (max-width: 1300px) {
  .wrapper {
    .wrapper__inner-content {
      .inner-content__table-wrap {
        .table-wrap__table {
          &.table-wrap__table--responsive {
            thead {
              tr {
                th {
                  &:nth-child(3) {
                    display: none;
                  }
                }
              }
            }
            tbody {
              tr {
                td {
                  &:nth-child(3) {
                    display: none;
                  }
                  .cell-info {
                    display: inline-flex;
                    align-items: center;
                    .cell-info__waiver-icon {
                      display: inline-flex;
                      padding-left: 6px;
                      svg {
                        font-size: 16px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  body {
    .header--sidebar-opened + .MuiDrawer-root + .wrapper {
      width: 100%;
    }
    .label {
      .label__text-info {
        @include fontSize-lineHeight(12, 18);
      }
    }
    .overlay-outer {
      display: block;
      .overlay {
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        position: fixed;
        z-index: 1205;
      }
    }
    .d-flex {
      width: 100%;
      margin-left: 0;

      .MuiDrawer-docked {
        .MuiDrawer-paper {
          width: 0;
          opacity: 0;
        }
      }
    }
    .wrapper {
      .wrapper__inner-content {
        .inner-content__table-wrap {
          .table-wrap__table {
            &.table-wrap__table--responsive {
              thead {
                tr {
                  th {
                    &:nth-child(3) {
                      display: none;
                    }
                  }
                }
              }
              tbody {
                tr {
                  td {
                    &:nth-child(3) {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
        .inner-content__heading-outer {
          &.inner-content__heading-outer--lg-content {
            &.inner-content__heading-outer--lg-content-large {
              .heading-outer__rgt-col {
                flex-direction: column-reverse;
                align-items: flex-start;
                .rgt-col__widget {
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: flex-start;
                  flex-wrap: wrap;
                  .widget__col {
                    margin-right: 15px;
                    &:last-child {
                      margin-right: 0;
                    }
                  }
                  &:last-child {
                    margin-bottom: 10px;
                  }
                }
                .MuiTextField-root {
                  margin: 0 0 0 15px;
                }
              }
            }
          }
        }
      }
    }
    .wrapper {
      &.wrapper--modal {
        padding: 0 30px 0 4px;
        .wrapper__inner-content {
          .inner-content__table-wrap {
            table {
              width: 1100px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  body {
    .wrapper {
      .accordion-content {
        .accordion-content__inner {
          flex-basis: 90%;
          max-width: 90%;
        }
      }
      .tabs-panel {
        .tabs-panel__inner {
          padding: 24px 32px;
        }
      }
      .tab-header {
        .MuiTab-root {
          min-width: auto;
          @include fontSize-lineHeight(15, 24);
          min-width: 85px;
          min-height: 60px;
        }
      }
      .wrapper__inner-content {
        .inner-content__heading-outer {
          &.inner-content__heading-outer--lg-content {
            h1 {
              margin-bottom: 15px;
            }
            flex-direction: column;
            align-items: flex-start;
            .heading-outer__rgt-col {
              align-items: flex-start;
              flex-direction: column-reverse;
              .rgt-col__widget {
                &:first-child {
                  margin-bottom: 5px;
                }
              }
              .MuiTextField-root {
                margin-right: 0;
              }
            }
          }
        }
      }
      .bg-content {
        .MuiGrid-item {
          max-width: 50%;
          flex-basis: 50%;
        }
      }
      .tabs-panel {
        .panel {
          &.panel--column {
            &.panel--large-label {
              .panel__content-row {
                padding: 0.6em 0.3em;
                label {
                  width: 64%;
                  @include fontSize-lineHeight(13, 20);
                }
                span {
                  @include fontSize-lineHeight(13, 20);
                }
              }
            }
          }
        }
      }
      .custom-panel {
        .custom-panel__rgt {
          .modal-row {
            margin: 10px 0 5px;
          }
          .label {
            &.label--sub-heading {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .card {
      padding: 20px 11px 15px;
      .card__header {
        @include fontSize-lineHeight(16, 28);
      }
      .card__body {
        @include fontSize-lineHeight(40, 50);
      }
      .card__footer {
        @include fontSize-lineHeight(15, 22);
      }
    }
  }
  .overlay-outer {
    .wrapper {
      .tabs-panel {
        .tabs-panel__inner {
          padding: 24px 15px;
        }

        .MuiAccordion-root {
          .MuiAccordionDetails-root {
            padding: 30px 20px;
          }
        }

        .panel .panel__lft {
          max-width: 45%;
          flex-basis: 45%;
        }

        .panel .panel__rgt {
          max-width: 55%;
          flex-basis: 55%;
          padding: 14px 12px;
        }
      }
    }
  }
}
@media screen and (max-width: 991px) and (min-width: 671px) {
  body
    .wrapper
    .wrapper__inner-content
    .inner-content__heading-outer.inner-content__heading-outer--border-btm
    .heading-outer__rgt-col--emp.heading-outer__rgt-col--emp-affiliate {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  body
    .wrapper
    .wrapper__inner-content
    .inner-content__heading-outer.inner-content__heading-outer--border-btm
    .heading-outer__rgt-col--emp.heading-outer__rgt-col--email--res-feedback {
    flex-direction: column-reverse;
    align-items: flex-end;
    max-width: 65%;
  }

  body
    .wrapper
    .wrapper__inner-content
    .inner-content__heading-outer.inner-content__heading-outer--border-btm
    .heading-outer__rgt-col--emp.heading-outer__rgt-col--emp-affiliate
    .primary-btn {
    margin-bottom: 10px;
  }
  body
    .wrapper
    .wrapper__inner-content
    .inner-content__heading-outer.inner-content__heading-outer--border-btm
    .heading-outer__rgt-col--emp.heading-outer__rgt-col--email--res-feedback
    .rgt-col__widget {
    margin-top: 10px;
  }
}
@media screen and (max-width: 991px) {
  body .wrapper .tabs-panel .inner-content__heading-outer.inner-content__heading-outer--inventory {
    align-items: flex-start;
    flex-direction: column;
  }
  body
    .wrapper
    .tabs-panel
    .inner-content__heading-outer
    .heading-outer__rgt-col.heading-outer__rgt-col--inventory {
    width: 100%;
  }
  body
    .wrapper
    .tabs-panel
    .inner-content__heading-outer
    .heading-outer__rgt-col.heading-outer__rgt-col--inventory
    .MuiTextField-root
    .MuiOutlinedInput-root {
    width: 100%;
  }
  body {
    .overlay-outer {
      display: block;

      .overlay {
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        width: 100%;
        position: fixed;
        z-index: 1205;
      }

      .wrapper {
        .accordion-content {
          justify-content: center;

          .accordion-content__inner {
            flex-basis: 100%;
            max-width: 100%;
          }
        }

        .wrapper__inner-content {
          .inner-content__heading-outer {
            h3 {
              @include fontSize-lineHeight(18, 25);
            }

            .heading-outer__rgt-col {
              .MuiOutlinedInput-root {
                min-width: 170px;
              }

              .primary-btn {
                padding: 8px 9px;
                @include fontSize-lineHeight(15, 22);
              }

              .darkblue-btn {
                padding: 8px 9px;
                @include fontSize-lineHeight(15, 22);
              }
            }
          }
        }
        .tab-header {
          .MuiTab-root {
            min-width: auto;
            @include fontSize-lineHeight(17, 24);
            min-width: 90px;
            min-height: 55px;
          }
        }
      }

      .accordion-panel--padding-sm {
        .panel {
          .panel__content-row {
            flex-direction: row;
            .MuiGrid-grid-md-5 {
              max-width: 100%;
              flex-basis: 100%;
            }
            .MuiGrid-grid-md-7 {
              max-width: 100%;
              flex-basis: 100%;
            }
          }
        }
      }
    }
    .card {
      padding: 15px;
      .card__header {
        @include fontSize-lineHeight(16, 24);
      }
      .card__footer {
        @include fontSize-lineHeight(15, 22);
      }
    }
    .wrapper {
      .wrapper__inner-content {
        .inner-content__heading-outer {
          &.inner-content__heading-outer--return-items {
            .filter-first-col {
              display: flex;
              .rgt-col__widget {
                margin-left: 10px !important;
              }
            }
            .heading-outer__rgt-col {
              .rgt-col__widget {
                margin: 0;
                .filter-wrap {
                  width: 100%;
                }
              }
            }
          }
          &.inner-content__heading-outer--lg-content {
            &.inner-content__heading-outer--lg-content-large {
              &.inner-content__heading-outer--filter-clear {
                .heading-outer__rgt-col {
                  .rgt-col__widget {
                    &:first-child {
                      .widget__col {
                        &:last-child {
                          margin-top: 0;
                        }
                      }
                    }
                  }
                  .filter-delete {
                    min-width: 160px !important;
                    max-height: 40px !important;
                  }
                }
              }
              .heading-outer__rgt-col {
                flex-direction: column-reverse;
                align-items: flex-start;
                .rgt-col__widget {
                  flex-direction: row;
                  align-items: flex-start;
                  justify-content: flex-start;
                  flex-wrap: wrap;
                  .widget__col {
                    margin-right: 15px;
                    margin-bottom: 10px !important;
                    &:last-child {
                      margin-right: 0;
                      margin-bottom: 0 !important;
                    }
                  }
                  &:last-child {
                    margin-bottom: 10px;
                  }
                }
                .MuiTextField-root {
                  margin: 0 0 0 15px;
                }
              }
            }
          }
          &.inner-content__heading-outer--return-items {
            flex-direction: column;
            align-items: flex-start;
            .heading-outer__rgt-col {
              &.heading-outer__rgt-col--rented-items {
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                width: 100%;
                .rgt-col__widget {
                  width: 100%;
                  margin: 0 10px 0 0;
                  .filter-wrap {
                    .filter-delete {
                      margin-left: 0;
                    }
                  }
                  .MuiTextField-root {
                    margin: 0;
                    width: 100%;
                  }
                  .MuiOutlinedInput-root {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  body {
    .profile {
      .profile__prf-im {
        display: table;
        justify-content: flex-start;
        padding-right: 0;
        max-width: 200px;
        flex-direction: column;
        position: relative;
        text-align: center;
        margin: 0 auto;
      }

      .profile__prf-form {
        max-width: 100%;
        flex-basis: 100%;
      }

      .browse {
        right: 0;
      }
    }

    .MuiStepper-root {
      padding-bottom: 15px;
    }
    .modal {
      .tab-header {
        .MuiTab-root {
          min-width: auto;
          @include fontSize-lineHeight(18, 24);
          min-width: 130px;
          min-height: 60px;
        }
      }
    }
    .wrapper {
      &.wrapper--modal {
        .wrapper__inner-content {
          .inner-content__table-wrap {
            &.inner-content__table-wrap--space {
              margin: 1.5em 0;
            }
          }
        }
      }
      .wrapper__inner-content {
        &.wrapper__inner-content--space {
          margin-bottom: 30px;
        }
        .inner-content__heading-outer {
          &.inner-content__heading-outer--border-btm {
            &.inner-content__tab-col {
              flex-direction: column;
              align-items: flex-start;
              .disp-flex {
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }
  }
  .tabs-panel {
    .panel {
      &.panel--column {
        .MuiGrid-container {
          .MuiGrid-item {
            &:first-child {
              border-right: none;
            }
          }
        }
      }
    }
  }
  .grey-bg {
    label {
      &.MuiFormControlLabel-root {
        padding: 0 20px 0 0;
      }
    }
  }
  .card {
    margin-top: 10px;
  }
}

@media screen and (max-width: 899px) {
  body {
    .widget {
      padding-left: 0;
      border-left: none;
    }
  }
  .wrapper {
    .wrapper__inner-content {
      .inner-content__heading-outer {
        &.inner-content__heading-outer--lg-content {
          flex-direction: column;
          align-items: flex-start;
          .heading-outer__rgt-col {
            align-items: flex-start;
          }
        }
        .heading-outer__rgt-col {
          &.heading-outer__rgt-col--customer {
            .rgt-col__widget {
              flex-direction: column;
              align-items: flex-end;
            }
            .MuiTextField-root {
              margin: 0 0 0 15px;
            }
            .filter-delete {
              margin-top: 12px;
            }
          }
        }
      }
    }
    .field-wrap__checkbox-mt {
      margin-top: 0;
    }
  }
}
@media screen and (max-width: 800px) {
  body {
    .wrapper {
      .wrapper__inner-content {
        .inner-content__heading-outer {
          .heading-outer__rgt-col {
            &.heading-outer__rgt-col--email--res-feedback {
              .MuiTextField-root {
                .MuiOutlinedInput-root {
                  min-width: 220px;
                }
              }
            }
            .MuiTextField-root {
              .MuiOutlinedInput-root {
                min-width: 200px;
              }
            }
            &.heading-outer__rgt-col--rented-items {
              flex-direction: column;
              align-items: flex-end;
              width: auto;

              .rgt-col__widget {
                margin: 0 !important;
                .filter-wrap {
                  margin-top: 5px;
                  flex-direction: column;
                  .rgt-col__widget {
                    margin: 5px 0 !important;
                  }
                }
                width: 100%;
                .filter-first-col {
                  flex-direction: column;
                  margin-top: 10px !important;
                  width: 100%;
                  .rgt-col__widget {
                    margin-top: 10px !important;
                    margin-left: 0 !important;
                  }
                }
              }
              .MuiTextField-root {
                min-width: 100%;
                margin: 0;
              }
              .MuiOutlinedInput-root {
                min-width: 100%;
              }
            }
          }
          &.inner-content__heading-outer--border-btm {
            border-bottom: 1px solid $lightGreyBorder;
            padding-bottom: 6px;
            .heading-outer__rgt-col--emp {
              &.heading-outer__rgt-col--emp-affiliate {
                .MuiTextField-root {
                  margin: 0 0 0 15px;
                }
              }
              &.heading-outer__rgt-col--email--res-feedback {
                .MuiTextField-root {
                  margin: 0 0 0 15px;
                }
              }
              flex-direction: column-reverse;
              align-items: flex-end;
              .primary-btn {
                margin-left: 15px;
                margin-bottom: 10px;
              }
              .darkblue-btn {
                margin-bottom: 10px;
              }
              .back-btn {
                margin-bottom: 10px;
              }
              .MuiTextField-root {
                margin: 0 15px 0 15px;
              }
              .rgt-col__widget {
                margin: 0;
                &.mr-1 {
                  margin-right: 0 !important;
                  .MuiTextField-root {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  body
    .wrapper
    .tabs-panel
    .inner-content__heading-outer
    .heading-outer__rgt-col.heading-outer__rgt-col--inventory {
    width: 100%;
    align-items: flex-start !important;
    flex-direction: column;
  }
  body
    .wrapper
    .tabs-panel
    .inner-content__heading-outer
    .heading-outer__rgt-col.heading-outer__rgt-col--inventory
    .rgt-col__widget {
    flex-direction: column;
  }
  body
    .wrapper
    .tabs-panel
    .inner-content__heading-outer
    .heading-outer__rgt-col.heading-outer__rgt-col--inventory
    .rgt-col__widget
    .MuiTextField-root {
    margin: 10px 0 0 0;
  }
  body
    .wrapper
    .tabs-panel
    .inner-content__heading-outer
    .heading-outer__rgt-col.heading-outer__rgt-col--inventory
    .rgt-col__widget
    .primary-btn {
    margin-left: 0;
  }
  body
    .wrapper
    .tabs-panel
    .inner-content__heading-outer
    .heading-outer__rgt-col.heading-outer__rgt-col--inventory
    .rgt-col__widget
    .filter-delete {
    margin: 10px 0 0 0;
  }
  body
    .wrapper
    .tabs-panel
    .inner-content__heading-outer
    .heading-outer__rgt-col.heading-outer__rgt-col--inventory
    .MuiTextField-root
    .MuiOutlinedInput-root {
    width: 100%;
  }
  body
    .wrapper
    .wrapper__inner-content
    .inner-content__heading-outer.inner-content__heading-outer--inventory
    .heading-outer__rgt-col.heading-outer__rgt-col--inventory
    .rgt-col__widget {
    width: 100%;
    max-width: 290px;
  }
  body
    .wrapper
    .wrapper__inner-content
    .inner-content__heading-outer.inner-content__heading-outer--inventory
    .heading-outer__rgt-col.heading-outer__rgt-col--inventory
    .MuiOutlinedInput-root {
    width: 100%;
  }
  body {
    .MuiCalendarPicker-root {
      width: 270px;
    }
    .MuiPickersPopper-root,
    .MuiPickersPopper-root .MuiPaper-root > div:first-child > div:first-child {
      width: 290px !important;
    }
    .MuiMenuItem-root {
      min-height: 37px;
    }
    .d-flex {
      width: 100%;
      margin-left: 0;

      .MuiDrawer-docked {
        .MuiDrawer-paper {
          width: 0;
        }
      }
    }
    .disp-flex {
      margin-bottom: 10px;
      align-items: flex-start;
    }
    .visible-mobile {
      display: block;
    }
    .hide-mobile {
      display: none;
    }
    .wrapper {
      .wrapper__inner-content--res-seetting {
        .inner-content__heading-outer {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
        }
      }
      .wrapper__inner-content {
        .content__heading {
          @include fontSize-lineHeight(18, 25);
        }

        .inner-content__heading-outer {
          margin-top: 0.5em;
          .heading-outer__rgt-col {
            &.heading-outer__rgt-col--customer {
              .MuiOutlinedInput-root {
                width: 100%;
              }
              .rgt-col__widget {
                flex-direction: column;
                align-items: flex-start;
              }
              .MuiTextField-root {
                margin: 10px 0 0 0;
              }
              .filter-delete {
                margin-top: 12px;
              }
            }
          }
          &.inner-content__heading-outer--lg-content {
            &.inner-content__heading-outer--filter-clear {
              .rgt-col__widget label + .MuiInputBase-root {
                min-width: 175px;
              }
            }
            .heading-outer__rgt-col {
              .rgt-col__widget {
                label + .MuiInputBase-root {
                  margin: 0 0 15px 0;
                  width: 100%;
                }
              }
            }
            &.inner-content__heading-outer--lg-content-large {
              .heading-outer__rgt-col {
                .rgt-col__widget {
                  .widget__col {
                    margin-right: 15px;
                    margin-bottom: 0 !important;
                    &:last-child {
                      margin-right: 0;
                    }
                  }
                  &:last-child {
                    margin-bottom: 10px;
                  }
                }
                .MuiTextField-root {
                  margin: 0 0 0 15px;
                }
              }
            }
          }
          &.inner-content__heading-outer--flex-col {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            h3 {
              @include fontSize-lineHeight(18, 25);
              margin-bottom: 10px;
            }
          }

          h1 {
            @include fontSize-lineHeight(18, 25);
          }

          .heading-outer__rgt-col {
            align-items: flex-start;

            .MuiOutlinedInput-root {
              min-width: 170px;
              width: 49%;
              &:first-child {
                margin-left: 0;
                margin-right: 2%;
              }
              &:nth-child(2) {
                margin-left: 0;
              }
            }

            .primary-btn {
              padding: 8px 9px;
              @include fontSize-lineHeight(15, 22);
            }

            .darkblue-btn {
              padding: 8px 9px;
              @include fontSize-lineHeight(15, 22);
            }
            &.heading-outer__rgt-col--emp {
              .darkblue-btn {
                margin-left: 0;
              }
              .date-range {
                margin-bottom: 8px;
              }
            }
          }

          &.inner-content__heading-outer--res {
            margin-top: 25px;
          }
          &.inner-content__heading-outer--lg-content {
            h1 {
              margin-bottom: 15px;
            }
            flex-direction: column;
            align-items: flex-start;
            .heading-outer__rgt-col {
              align-items: flex-start;
              flex-direction: column-reverse;
              .rgt-col__widget {
                flex-wrap: wrap;
                &:first-child {
                  margin-bottom: 5px;
                }
              }
              .MuiTextField-root {
                margin: 0;
                width: 100%;
                .MuiOutlinedInput-root {
                  width: 100%;
                  margin: 0;
                }
              }
            }
          }
          &.inner-content__heading-outer--flex-wrap {
            .heading-outer__flex-box {
              margin-bottom: 10px !important;
            }
          }
          &.inner-content__heading-outer--return-items {
            flex-direction: column;
            align-items: flex-start;
            .heading-outer__rgt-col {
              &.heading-outer__rgt-col--rented-items {
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                .rgt-col__widget {
                  width: 100%;
                  margin: 0 !important;
                  .MuiTextField-root {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
        &.wrapper__inner-content--tabs-padding-sm {
          .tab-header {
            .MuiTab-root {
              margin-bottom: 5px;
              border-radius: 5px;
              box-shadow: none;
              border: 1px solid $tabBgColor;
            }
          }
          .tabs-panel {
            .tabs-panel__inner {
              padding: 20px 15px;
              border: 1px solid $tabBgColor;
              border-radius: 5px;
            }
          }
        }
      }

      .heading-outer__badge {
        @include fontSize-lineHeight(12, 20);
        padding: 3px 4px;
        margin-left: 7px;
      }

      .tab-header {
        .MuiTab-root {
          min-width: auto;
          @include fontSize-lineHeight(16, 24);
          min-height: 48px;
        }
        &.tab-header--more-tabs {
          .MuiTabs-flexContainer {
            width: 100%;
            flex-wrap: wrap;
            flex-direction: column;
          }
          .MuiTab-root {
            width: 100%;
            max-width: 100%;
            margin-right: 0;
          }
        }
      }
      .tabs-panel {
        .tabs-panel__inner {
          padding: 20px 15px;
        }
        .MuiAccordion-root {
          .MuiAccordionDetails-root {
            padding: 20px 20px;
          }
        }

        .inner-content__heading-outer {
          align-items: flex-start;
          flex-direction: column;
          h3 {
            margin: 0 0 12px;
          }

          .primary-btn {
            margin-bottom: 6px;
          }
          .darkblue-btn {
            margin-bottom: 6px;
          }
          &.inner-content__heading-outer--pt {
            h3 {
              margin: 7px 0 0 0;
            }
          }
        }
        .panel {
          .panel .panel__rgt {
            .rgt__btn-group {
              .primary-btn {
                margin-left: 0;
                width: 100%;
              }

              .delete-btn {
                width: 100%;
                margin-right: 0;
              }
            }
          }
        }
      }
      .accordion-panel {
        &.accordion-panel--padding-sm {
          .inner-content__table-wrap {
            margin-bottom: 15px;
          }
        }
      }
      .bg-content {
        &.mb-2 {
          .MuiGrid-item {
            max-width: 100%;
            flex-basis: 100%;
          }
        }
      }
    }
    .modal {
      .tab-header {
        .MuiTab-root {
          min-width: auto;
          @include fontSize-lineHeight(15, 24);
          min-width: auto;
          min-height: 50px;
        }
      }
    }
    .disp-block {
      display: block;
    }
    .MuiDialogContent-root.MuiDialogContent-dividers {
      margin: 0 12px;
      padding: 16px 2px;
    }
    .MuiTypography-root {
      @include fontSize-lineHeight(14, 20);
    }
    .wrapper--cms {
      .label {
        @include fontSize-lineHeight(16, 22);
        display: inline-block;
      }
      .toggle-wrap {
        .label {
          margin-bottom: 10px;
        }
      }
    }
  }
  .error-page {
    padding: 40px 30px;
    img {
      width: 150px;
    }
    .error-page__heading {
      @include fontSize-lineHeight(30, 33);
      color: $black;
      font-family: $fontFamily;
      text-align: center;
    }
    .error-page__sub-heading {
      @include fontSize-lineHeight(22, 33);
      margin: 0;
    }
    p {
      @include fontSize-lineHeight(15, 23);
      margin-top: 10px;
    }
    .error-page__link {
      @include fontSize-lineHeight(14, 20);
      font-weight: 700;
      svg {
        @include fontSize-lineHeight(14, 20);
      }
    }
  }
  .panel {
    .panel__content-row {
      .MuiFormControlLabel-root {
        margin-right: 0;
        margin-bottom: 5px;
      }
    }
  }
  .tab-header {
    .MuiTabs-root {
      padding: 7px 0 0;
      width: 100%;
    }
  }
  .tabs-panel {
    margin: 0;
  }
}

@media screen and (max-width: 670px) {
  body {
    .wrapper {
      .wrapper__inner-content {
        .inner-content__heading-outer {
          h3 {
            @include fontSize-lineHeight(18, 22);
          }
        }

        .inner-content__heading-outer {
          .widget__col-btn-grp {
            flex-direction: column;
            .filter-delete {
              margin: 0 0 10px;
            }
          }
          &.inner-content__heading-outer--lg-content-large {
            &.inner-content__heading-outer--filter-clear {
              .heading-outer__rgt-col {
                width: 100%;
                .rgt-col__widget {
                  width: 100%;
                  .widget__col {
                    flex-direction: column;
                    margin-right: 0 !important;
                    width: 100%;
                    .date-range {
                      margin: 15px 0 !important;
                    }
                  }
                }
              }
            }
          }
          &.inner-content__heading-outer--mb-0 {
            flex-direction: column;
            align-items: flex-start;

            h3 {
              margin-bottom: 10px;
            }

            .heading-outer__rgt-col {
              width: 100%;

              .rgt-col__widget--wd {
                width: 100%;

                .MuiAutocomplete-root {
                  min-width: 100%;
                }
              }
            }
          }

          &.inner-content__heading-outer--res {
            flex-direction: column;
            align-items: flex-start;
            margin-top: 25px;

            h3 {
              margin-bottom: 10px;
            }
          }

          &.inner-content__heading-outer--lg-content {
            flex-direction: column;
            align-items: flex-start;
            .heading-outer__rgt-col {
              align-items: flex-start;
              .rgt-col__widget {
                flex-wrap: wrap;
                .MuiAutocomplete-root {
                  margin: 15px 0 0;
                  width: 100%;
                }
              }
            }
          }
          &.inner-content__heading-outer--mob-flex-col {
            flex-direction: column;
            align-items: flex-start;
            h1 {
              margin-bottom: 8px;
            }
          }
          &.inner-content__heading-outer--border-btm {
            &.inner-content__heading-outer--emp {
              flex-direction: column;
              align-items: flex-start;
            }
            border-bottom: 1px solid $lightGreyBorder;
            padding-bottom: 6px;
            .heading-outer__rgt-col--emp {
              flex-direction: column-reverse;
              align-items: flex-start;
              width: 100%;
              margin-top: 10px;
              &.heading-outer__rgt-col--emp-affiliate {
                .MuiTextField-root {
                  margin: 10px 0 0 0;
                  width: 100%;
                }
              }
              &.heading-outer__rgt-col--email--res-feedback {
                .MuiTextField-root {
                  margin: 10px 0 0 0;
                  width: 100%;
                }
              }
              .MuiOutlinedInput-root {
                width: 100%;
              }
              .primary-btn {
                margin-left: 0;
                margin-bottom: 10px;
              }
              .back-btn {
                margin-bottom: 0;
              }
              .MuiTextField-root {
                margin: 10px 0 0 0;
                width: 100%;
              }
              .rgt-col__widget {
                margin: 0;
                flex-direction: column;
              }
            }
            .heading-outer__rgt-col--return {
              margin-top: 8px;
              .MuiTextField-root {
                margin: 0;
              }
            }
          }
        }
      }

      .accordion-content {
        .accordion-content__inner {
          flex-basis: 100%;
          max-width: 100%;
        }

        .panel {
          flex-direction: column;

          .panel__lft {
            flex-basis: 100%;
            max-width: 100%;
            padding: 15px 10px;
          }

          .panel__rgt {
            flex-basis: 100%;
            max-width: 100%;

            .rgt__btn-group {
              .delete-btn {
                width: 100%;
                margin: 0 0 10px;
              }
            }
          }
        }
      }
    }
    .tabs-panel {
      .panel {
        .panel__lft {
          img {
            border-radius: 10px 10px 0 0;
          }
        }
      }
    }
    .modal {
      .tabs-panel {
        .tabs-panel__inner {
          padding: 20px 16px;
        }
        .panel {
          .panel__header {
            flex-direction: column;
          }
          .panel__content-row {
            flex-direction: column;
            label {
              width: 100%;
            }
          }
        }
      }
      .tab-header {
        &.tab-header--more-tabs {
          .MuiTabs-flexContainer {
            flex-wrap: wrap;
            .MuiTab-root {
              min-width: auto;
              @include fontSize-lineHeight(15, 24);
              width: 100%;
              margin-right: 0;
              min-height: 50px;
            }
          }
        }
      }
    }
    .mb-disp-block {
      display: block;
    }
  }
}

@media screen and (max-width: 599px) {
  body {
    .w-100--mob-padding {
      padding: 24px 0;
    }
    .profile-menu {
      .MuiMenu-list {
        .MuiMenuItem-root {
          min-height: 25px;
        }
      }
    }
    .wrapper {
      padding: 32px 15px;
      .bg-content {
        .MuiGrid-item {
          max-width: 100%;
          flex-basis: 100%;
        }
      }
      &.wrapper--modal {
        display: block;
        padding: 0 5px;
        min-width: 200px;
      }

      .wrapper__inner-content {
        .MuiTablePagination-displayedRows {
          float: left;
          right: 0;
          clear: both;
          width: auto;
        }

        .MuiTablePagination-actions {
          float: left;
          margin: 10px 0 0 10px;
          position: relative;
          right: 0;
        }
        .inner-content__heading-outer {
          &.inner-content__heading-outer--lg-btn {
            flex-direction: column;
            align-items: flex-start;
            h3 {
              margin: 0 0 10px;
            }
          }
          .heading-outer__btn-wrap {
            &.heading-outer__btn-wrap--ml {
              .primary-btn {
                margin-left: 0;
                width: 260px;
              }
            }
          }
        }
      }

      .tab-header {
        .MuiTab-root {
          min-width: auto;
          @include fontSize-lineHeight(16, 24);
          min-height: 48px;
        }
      }

      .tabs-panel {
        .MuiAccordion-root {
          .MuiAccordionDetails-root {
            padding: 20px 20px;
          }
        }

        .inner-content__heading-outer {
          align-items: flex-start;
          flex-direction: column;

          h3 {
            margin: 0 0 20px;
          }

          .primary-btn {
            margin-bottom: 6px;
          }

          .darkblue-btn {
            margin-bottom: 6px;
          }
          &.reservation-right-align {
            .heading-outer__rgt-col {
              .primary-btn {
                margin-left: 10px;
                margin-top: 5px;
              }
            }
          }
          .heading-outer__rgt-col {
            .rgt-col__widget {
              flex-direction: column;
              margin: 0;
              .MuiTextField-root {
                margin: 0;
                width: 100%;
              }
            }
            .MuiOutlinedInput-root {
              margin: 5px 0px;
            }
            .primary-btn {
              margin-left: 0;
            }
            .darkblue-btn {
              margin: 5px 0;
            }
          }
        }
      }
      .custom-panel {
        .custom-panel__rgt {
          &:before {
            border: none;
          }
        }
      }
    }

    .tabs-panel {
      .panel {
        .panel__rgt {
          .rgt__btn-group {
            .primary-btn {
              margin-left: 0;
              width: 100%;
            }

            .delete-btn {
              width: 100%;
              margin-right: 0;
            }
          }
        }
      }
    }
    .accordion-headerrow {
      flex-direction: column;
      align-items: flex-start;
      p {
        margin-bottom: 3px;
      }
    }
    .modal {
      .modal__footer {
        &.MuiDialogActions-root {
          .primary-btn {
            padding: 6px 9px;
            @include fontSize-lineHeight(15, 24);
          }
        }
      }
    }

    .pt-10 {
      padding-top: 10px !important;
    }
  }

  .accordion-headerrow {
    flex-direction: column;

    p {
      margin-bottom: 3px;
    }
  }
  .modal {
    .modal__footer {
      &.MuiDialogActions-root {
        .primary-btn {
          padding: 6px 9px;
          @include fontSize-lineHeight(15, 24);
        }
      }
    }
  }
  .accordion-headerrow {
    flex-direction: column;
    align-items: flex-start;
    p {
      margin-bottom: 3px;
    }
  }
  .modal {
    .modal__footer {
      &.MuiDialogActions-root {
        .primary-btn {
          padding: 6px 9px;
          @include fontSize-lineHeight(15, 24);
        }
      }
    }
  }

  .btn-wrap--justify-between {
    justify-content: space-between;
  }
}
@media screen and (max-width: 500px) {
  body {
    .wrapper {
      .wrapper__inner-content {
        .inner-content__heading-outer {
          &.inner-content__heading-outer--lg-content {
            &.inner-content__heading-outer--lg-content-large {
              .heading-outer__rgt-col {
                .rgt-col__widget {
                  .widget__col {
                    flex-direction: column;
                    margin-right: 0 !important;
                    width: 100%;
                    &:last-child {
                      margin-right: 0;
                      .MuiTextField-root {
                        margin: 0;
                      }
                    }
                    .date-range {
                      margin: 0 0 15px;
                    }
                  }
                  &:last-child {
                    margin-bottom: 10px;
                  }
                  width: 100%;
                  .darkblue-btn {
                    width: 100%;
                    margin: 0 0 10px;
                  }
                  .primary-btn {
                    width: 100%;
                    margin: 0;
                  }
                }
                .MuiTextField-root {
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  .tab-header {
    flex-direction: column-reverse;
    .MuiTabs-root {
      flex-direction: column;
      .MuiTabs-scroller {
        display: flex;
        flex-direction: column;
        white-space: normal;
        .MuiTabs-flexContainer {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  body {
    .wrapper {
      .wrapper__inner-content {
        .inner-content__heading-outer {
          &.inner-content__heading-outer--return-items {
            .heading-outer__rgt-col--rented-items {
              .rgt-col__widget {
                .filter-first-col .rgt-col__widget {
                  margin: 0 !important;
                }
                .filter-wrap {
                  margin-top: 0 !important;
                  .rgt-col__widget {
                    margin: 0 !important;
                  }
                }
              }
            }
          }
          &.inner-content__heading-outer--lg-content {
            .heading-outer__rgt-col {
              .rgt-col__widget {
                &:last-child {
                  margin-bottom: 10px;
                }
              }
            }
          }
          &.inner-content__heading-outer--border-btm {
            &.inner-content__heading-outer--btn {
              h3 {
                margin-bottom: 5px;
              }
            }
            &.inner-content__heading-outer--lg-btn {
              h3 {
                margin-bottom: 8px;
              }
            }
            .heading-outer__rgt-col--return {
              margin-top: 8px;
              .MuiTextField-root {
                margin: 0;
              }
              .MuiOutlinedInput-root {
                margin: 0;
              }
            }
          }

          &.inner-content__heading-outer--btn {
            h1 {
              margin-bottom: 5px;
            }
          }
          &.inner-content__heading-outer--mob-flex-col {
            .heading-outer__btn-wrap {
              .delete-btn {
                margin-bottom: 8px;
              }
            }
          }
          .heading-outer__rgt-col {
            align-items: flex-start;

            .MuiOutlinedInput-root {
              min-width: 220px;
              width: 100%;
              margin: 5px 0px;
            }

            .primary-btn {
              padding: 8px 9px;
              @include fontSize-lineHeight(15, 22);
              margin-left: 0;
            }

            .darkblue-btn {
              padding: 8px 9px;
              @include fontSize-lineHeight(15, 22);
              margin-top: 5px;
            }

            .MuiAutocomplete-root {
              margin: 0;
            }

            .rgt-col__widget {
              flex-direction: column;
              margin: 0;
            }
          }

          &.inner-content__heading-outer--lg-content {
            flex-direction: column;
            align-items: flex-start;
            .heading-outer__rgt-col {
              width: 100%;
              .rgt-col__widget {
                width: 100%;
                .MuiAutocomplete-root {
                  margin: 0;
                }
                .MuiTextField-root {
                  margin: 0;
                }
              }
              .darkblue-btn {
                margin: 8px 0;
              }
            }
          }
          .heading-outer__btn-wrap {
            &.heading-outer__btn-wrap--ml {
              .primary-btn {
                margin-left: 0;
                width: 200px;
              }
            }
          }
        }
      }
    }
    .modal__footer {
      &.modal__footer--flex {
        flex-direction: column;
        .primary-btn {
          width: 100%;
          margin-bottom: 10px;
        }
        .footer__flex {
          margin-top: 10px;
          flex-direction: column;
          width: 100%;
          .primary-btn {
            width: 100%;
          }
          .cancel-btn {
            margin: 10px 0 0 0;
          }
        }
        .modal__footer {
          width: 100%;
          div {
            flex-direction: column;
            .back-btn {
              margin: 0 0 10px;
            }
            .primary-btn {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    .affilation-check-wrap {
      .MuiFormControlLabel-root {
        margin-right: 14px;
        .MuiCheckbox-root {
          padding: 9px 4px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  body {
    .copyright-footer {
      @include fontSize-lineHeight(13, 18);
      width: 290px;
      text-align: center;
    }
    .wrapper {
      min-width: 270px;

      .wrapper__inner-content {
        .MuiTablePagination-displayedRows {
          float: left;
          right: 0;
          clear: both;
        }

        .MuiTablePagination-actions {
          float: left;
          margin: 10px 0 0 10px;
          position: relative;
          right: 0;
        }

        .inner-content__heading-outer {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          margin-top: 0;

          &.inner-content__heading-outer--back-btn {
            h1 {
              margin: 0 0 15px;
            }

            h3 {
              margin-bottom: 15px;
            }
          }
          &.inner-content__heading-outer--border-btm {
            .heading-outer__rgt-col--return {
              margin-top: 8px;
              flex-direction: column-reverse;
              align-items: flex-start;
              .MuiTextField-root {
                margin: 0;
              }
              .MuiOutlinedInput-root {
                margin: 0;
              }
              .rgt-col__widget {
                &:last-child {
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
        &.wrapper__inner-content--tabs-padding-sm {
          .tabs-panel {
            .tabs-panel__inner {
              padding: 20px 15px;
            }
          }
        }
      }
      .tabs-panel {
        .tabs-panel__inner {
          padding: 24px 15px;
        }
      }
    }
    .modal {
      .wrapper {
        min-width: auto;
      }
    }
  }
}

@media screen and (max-width: 340px) {
  .wrapper {
    .btn-wrap {
      &.btn-wrap--small-res {
        display: flex;
        flex-direction: column;

        .cancel-btn {
          margin: 10px 0 0;
        }
      }
    }
  }
}
