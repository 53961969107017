@import '../../components/Variables.scss';
@import '../../components/Mixin.scss';

.modal {
  .modal__heading {
    @include fontSize-lineHeight(25, 32);
    color: $darkGrey;
    font-family: $fontFamily;
    font-weight: 700;
    padding: 16px 24px;
    .modal-sub-heading {
      font-size: 20px;
    }
  }
  .MuiInputBase-adornedEnd {
    .MuiIconButton-root {
      margin-top: 0;
      margin-right: 0;
      padding: 5px 0;
    }
  }
  .MuiIconButton-root {
    margin-top: 4px;
    margin-right: 6px;
    &.card-icon {
      margin: 0;
      color: $cardIconColor !important;
      padding: 0;
      svg {
        font-size: 2rem;
      }
    }
  }
  .modal-lcol {
    border-right: 1px solid $borderLight;
    padding-right: 40px;
    &.modal-lcol--border-none {
      border-right: none;
      padding-right: 0;
    }
    .modal-col__row {
      margin: 0 0 1em;
      .row__label {
        margin: 0 0 0.25em;
        @include fontSize-lineHeight(18, 28);
        display: inline-flex;
        color: $darkGrey;
        span {
          color: $inputRequired;
          display: inline-block;
          margin-left: 0.25em;
        }
      }
    }
  }
  .modal-rcol {
    padding-left: 40px;
    .modal-rcol__heading {
      @include fontSize-lineHeight(17, 24);
      font-weight: 700;
      color: $textPrimary;
      margin: 0 0 0.5em;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        @include fontSize-lineHeight(16, 27);
        color: $darkGrey;
        background: url(../../assets/list.png);
        background-repeat: no-repeat;
        background-position: 0px 8px;
        padding-left: 25px;
        &.tick {
          background: url(../../assets/list-tick.png);
          background-repeat: no-repeat;
          background-position: 0px 8px;
        }
      }
    }
  }
  &.modal--card {
    .modal-lcol {
      .modal-col__row {
        margin-bottom: 0;
      }
    }
    .modal__footer {
      margin-top: 25px;
    }
  }
  .modal__footer {
    &.MuiDialogActions-root {
      background: $adminModalFooterBg;
      padding: 15px 20px;
      .primary-btn {
        border-radius: 3px;
        @include fontSize-lineHeight(16, 24);
        text-transform: capitalize;
        font-weight: normal;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        padding: 6px 12px;
        &:hover {
          background: $darkBlue;
        }
      }
      .cancel-btn {
        border: 1px solid $btnCancelBorder;
        color: $btnCancelColor;
        border-radius: 3px;
        @include fontSize-lineHeight(16, 24);
        text-transform: capitalize;
        font-weight: normal;
        margin-left: 18px;
        padding: 6px 12px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        &:hover {
          background: $white;
          color: $btnPrimary;
          border-color: $btnPrimary;
        }
      }
    }
  }
  .MuiDialog-paperWidthSm {
    max-width: 767px;
  }
  .MuiDialog-paperWidthMd {
    max-width: 850px;
  }
}
body {
  .flex-row {
    flex-direction: row;
    .MuiFormControlLabel-root {
      margin: 0.3em 1.3em 0.8em 0;
    }
  }
}
@media only screen and (max-width: 900px) {
  .modal {
    .modal-lcol {
      padding-right: 15px;
    }
    .modal-rcol {
      padding-left: 15px;
      ul {
        li {
          background-position: 0 11px;
          background-size: 9px;
          @include fontSize-lineHeight(14, 27);
          padding-left: 19px;
          &.tick {
            background-position: 0 11px;
            background-size: 13px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 599px) {
  .modal {
    .modal__heading {
      @include fontSize-lineHeight(18, 26);
      padding-right: 50px;
    }
    .modal-rcol {
      padding-left: 5px;
      width: 100%;
      margin-top: 20px;
      ul {
        li {
          margin-bottom: 8px;
          @include fontSize-lineHeight(14, 20);
          background-position: 0 6px;
          &.tick {
            background-position: 0 7px;
          }
        }
      }
    }
    .modal-lcol {
      padding-right: 0;
      border: none;
      width: 100%;
      &.modal-lcol--border-none {
        width: auto;
      }
      .modal-col__row {
        .row__label {
          @include fontSize-lineHeight(16, 24);
        }
      }
      &.modal-lcol--border-none {
      }
    }
    .flex-row {
      .MuiFormControlLabel-root {
        margin: 0.3em 0.5em 0.8em 0;
      }
    }
  }
}
