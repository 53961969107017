@import '../../components/Variables.scss';
@import '../../components/Mixin.scss';
.field-wrap {
  .MuiTextField-root {
    width: 100%;
  }
  svg {
    position: relative;
    top: 2px;
    margin-left: 3px;
  }
}
body {
  .accordion-panel--grid {
    .MuiAccordion-root {
      .MuiAccordionSummary-root {
        border-radius: 4px;
        &.Mui-expanded {
          border-radius: 4px 4px 0 0;
        }
      }
    }
    .MuiGrid-grid-md-3 {
      flex-basis: 20%;
      max-width: 20%;
    }
    .modal-row {
      .modal-row__heading {
        @include fontSize-lineHeight(18, 28);
        text-transform: capitalize;
        font-weight: 400;
      }
      .modal-row__desc {
        @include fontSize-lineHeight(17, 25);
      }
    }
    .accordion-panel__shadow {
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.2);
      border-radius: 4px !important;
      border: none;
    }
  }
  .modal {
    label {
      margin: 0;
    }
  }
}

.hints {
  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 0;
    li {
      @include fontSize-lineHeight(16, 20);
      align-items: center;
      display: flex;
      list-style: none;
      &:first-child {
        &:before {
          content: '';
          padding: 0;
        }
      }
      &:before {
        content: '|';
        padding: 0 10px;
      }
      .hints__badge {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        display: inline-flex;
        border-radius: 2px;
        border: 1px solid transparent;
        &.hints__badge--cancel {
          background: #fdeded;
          border-color: #d9bebe;
        }
        &.hints__badge--pend {
          background: $warningBgColor;
          border-color: #cec0ae;
        }
        &.hints__badge--comp {
          background: #edf7ed;
          border-color: #c3cec3;
        }
      }
    }
  }
}

.accordion-headerrow--iconbutton {
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  p {
    margin: 0;
    &:last-child {
      margin-right: 1.3em;
    }
  }
  .accordion-headerrow__edit {
    width: 30px;
    height: 30px;
    background: $white;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    svg {
      font-size: 1em;
      color: $darkBlue;
    }
  }
  .accordion-headerrow__delete {
    @extend .accordion-headerrow__edit;
    img {
      width: 13px;
    }
  }
}
.modal {
  .modal__heading {
    &.modal__heading--border-space {
      border-bottom: 1px solid $lightGreyBoder;
      margin: 0 24px 25px;
      padding: 16px 0;
    }
  }
  .modal-lcol {
    &.modal-lcol--mt {
      margin: 0;
      width: 100%;
      padding: 10px 50px 15px;
    }
    .MuiFormControl-root {
      width: 100%;
    }
  }
  .renter-detail {
    margin-top: 2px;
    &.MuiGrid-root {
      .MuiGrid-item {
        padding-top: 0;
        padding-bottom: 11px;
      }
    }
  }
  .radio-block {
    padding-top: 0;
  }
}
.MuiStepper-root {
  .MuiStepLabel-label {
    &.MuiStepLabel-alternativeLabel {
      margin-top: 3px;
      font-family: $fontFamily;
      @include fontSize-lineHeight(15, 20);
      padding-top: 4px;
      color: $modalHeadingColor;
      font-weight: 600;
    }
  }
}

.MuiStepLabel-root {
  display: flex;
  flex-direction: column;
  .MuiStepLabel-iconContainer {
    padding-right: 0;
  }
}
.radio-block {
  padding-top: 4px;
  width: 100%;
  .MuiGrid-item {
    padding-left: 24px;
  }
  &.radio-block--inner-space {
    .MuiGrid-item {
      margin-bottom: 1.5em;
    }
    .MuiGrid-root {
      &:last-child {
        .MuiGrid-item {
          margin-bottom: 0;
        }
      }
    }
  }
  .align-center {
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .align-end {
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
  }
}
.border-block > .MuiRadio-root > input[type='radio']:checked {
  border-color: $cardBgDarkBlue;
}
.border-block {
  border: 1px solid $lightGreyBoder;
  border-radius: 10px;
  height: 100%;
  // padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 150px;
  overflow: hidden;
  &.border-block--pkg-addon {
    flex-direction: row;
    position: relative;
    .border-block__img-block {
      flex: 0 0 40%;
      max-width: 40%;
      .border-block__desc {
        display: flex;
        width: 100%;
        padding: 0 0 8px;
      }
      .border-block__img {
        position: relative;
        z-index: 2;
      }
      .border-block__price {
        left: unset;
        right: -154%;
        top: -41px;
        border-radius: 0 0 0 10px;
      }
    }
    .border-block__addon-block {
      flex: 0 0 60%;
      max-width: 60%;
      padding-left: 15px;
      .MuiDialogContent-root {
        padding: 0 0 0 15px;
      }
      .panel {
        box-shadow: none;
        .panel__header {
          border-bottom: 1px solid $lightGreyBoder;
          display: flex;
          padding: 0 0 0.5em;
          strong {
            font-weight: normal;
            @include fontSize-lineHeight(15, 22);
            &.text-uppercase {
              text-transform: capitalize;
            }
          }
          .MuiGrid-item {
            padding-left: 0;
          }
        }
        .panel__content-row {
          padding: 0;
          .MuiFormControlLabel-root {
            position: relative;
            z-index: 4;
          }
          .MuiGrid-root {
            padding-left: 5px;
          }
        }
      }
    }
  }
  &:hover {
    border-color: $darkGrey;
  }
  &:after {
    content: '';
    border: 3px solid transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 10px;
  }
  .MuiRadio-root {
    opacity: 0;
  }
  .border-block__img {
    text-align: center;
    min-height: 168px;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 15px 10px 13px;
    position: relative;
    img {
      // max-width: 200px;
      // max-height: 200px;
      max-width: 100%;
    }
    &.border-block__img--sub-package {
      img {
        max-width: 140px;
        max-height: 135px;
        width: auto;
        height: auto;
      }
    }
  }
  .MuiRadio-root {
    position: absolute;
    right: -5px;
    top: -5px;
  }
  .border-block__desc {
    border-top: 1px solid $lightGreyBoder;
    color: $darkGrey;
    @include fontSize-lineHeight(14, 22);
    padding: 10px;
    display: inline-flex;
    align-items: center;
    position: relative;
    z-index: 4;
    &.border-block__desc--border-brm {
      border-top: none;
      border-bottom: 1px solid $lightGreyBoder;
    }
    svg {
      margin-right: 5px;
    }
  }
  &.border-block--fixed-width {
    max-width: 100%;
    .MuiRadio-root {
      right: -7px;
      top: -7px;
    }
  }
  &.border-block--selected {
    border-color: $cardBgDarkBlue;
    &:after {
      border-color: $cardBgDarkBlue;
    }
  }
  .border-block__price {
    color: $white;
    @include fontSize-lineHeight(13, 19);
    background: $cardBgDarkBlue;
    display: inline-flex;
    position: absolute;
    top: -11px;
    left: -10px;
    padding: 4px 10px;
    border-radius: 10px 0 10px 0;
  }
  .border-block__label-selection {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 9;

    &.border-block__label-selection--selected {
      z-index: 0;
    }
  }
}
.block-shadow {
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  .block-shadow__heading {
    @include fontSize-lineHeight(17, 25);
    text-transform: uppercase;
    border-bottom: 3px solid $darkBlue;
    padding: 8px 20px;
    font-weight: 700;
  }
  .block-shadow__content {
    padding: 5px 20px 12px;
    .MuiFormGroup-root {
      flex-direction: row;
    }
  }
}
.subpackage-alert {
  display: none;
}
.block-heading {
  color: $modalHeadingColor;
  @include fontSize-lineHeight(20, 30);
  font-weight: 700;
  margin: 0;
  border-bottom: 1px solid $lightGreyBoder;
  width: 100%;
  padding-bottom: 0.2em;
  margin-bottom: 0.8em;
  &.block-heading--disp-fl {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .block-heading__rgt-col {
    font-weight: normal;
    @include fontSize-lineHeight(14, 20);
  }
  &.block-heading--sm {
    font-weight: normal;
    @include fontSize-lineHeight(16, 22);
    padding-top: 3px;
    padding-bottom: 5px;
  }
}
.panel {
  width: 100%;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  .panel__header {
    border-bottom: 3px solid $darkBlue;
    display: flex;
    padding: 0.8em 1em 0.5em;
    strong {
      @include fontSize-lineHeight(20, 22);
    }
  }
  .panel__content-row {
    display: flex;
    padding: 0.6em 1em;
    .MuiOutlinedInput-input {
      padding: 9px 14px;
    }
    &:nth-child(odd) {
      background: $rowBgColor;
    }
  }
}
.grey-block {
  background: $labelBg;
  border: 1px solid $greyBlockBorder;
  padding: 12px 20px;
  color: $darkBlue;
  @include fontSize-lineHeight(16, 24);
  border-radius: 3px;
  margin-top: 2.2em;
}
.tooltip__list {
  @include fontSize-lineHeight(14, 20);
}

// Added styling for background color indicators
.paper-wrapper {
  margin-top: 10px;
  margin-bottom: 15px;
  padding: 20px;
}
.color-indicator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;

  &.reservation-completed-color {
    background: #edf7ed;
    border: 1px solid #aeddae;
  }

  &.reservation-pending-color {
    background: #fff4e5;
    border: 1px solid #dbc7ab;
  }

  &.reservation-cancelled-color {
    background: #fdeded;
    border: 1px solid #ddacac;
  }
}

// background color code indicator css ends

@media screen and (max-width: 1500px) {
  body {
    .accordion-panel--grid {
      .MuiGrid-grid-md-3 {
        flex-basis: 25%;
        max-width: 25%;
      }
    }
  }
}
@media screen and (max-width: 1300px) {
  body {
    .accordion-panel--grid {
      .MuiGrid-grid-md-3 {
        flex-basis: 33.33%;
        max-width: 33.33%;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  body {
    .accordion-panel--grid {
      .MuiGrid-grid-md-3 {
        flex-basis: 50%;
        max-width: 50%;
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .modal {
    .modal-lcol.modal-lcol--mt {
      padding-top: 0;
    }
  }
  .border-block__img {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  body {
    .block-shadow {
      .block-shadow__heading {
        @include fontSize-lineHeight(14, 20);
      }
    }
    .accordion-panel--grid {
      .MuiGrid-grid-md-3 {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
    .accordion-headerrow--iconbutton {
      p {
        margin: 0 0 7px;
        &:last-child {
          margin-right: 0.5em;
        }
      }
    }
    .accordion-panel {
      .MuiAccordion-root {
        .MuiAccordionDetails-root {
          padding: 20px 15px;
        }
      }
      &.accordion-panel--grid {
        .modal-row {
          .modal-row__heading {
            @include fontSize-lineHeight(14, 20);
          }
          .modal-row__desc {
            @include fontSize-lineHeight(14, 20);
            padding: 9px 12px;
          }
        }
      }
    }
    .label {
      @include fontSize-lineHeight(15, 20);
    }
    .field-wrap {
      .label {
        svg {
          top: -1px;
        }
      }
    }
    .primary-btn {
      @include fontSize-lineHeight(15, 20);
      padding: 7px 15px;
    }
    .cancel-btn {
      @include fontSize-lineHeight(15, 20);
      padding: 7px 15px;
    }
    .back-btn {
      @include fontSize-lineHeight(15, 20);
      padding: 5px 15px;
    }
    .radio-block {
      &.radio-block--inner-space {
        .MuiGrid-item {
          max-width: 100%;
          flex-basis: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .align-center {
        display: block;
      }
      .align-end {
        display: block;
      }
      .border-block {
        &.border-block--fixed-width {
          &.border-block--pkg-addon {
            flex-direction: column;
            max-width: 100%;
            width: 100%;
            height: auto;
            min-height: auto;
            .MuiGrid-item {
              padding-left: 11px;
            }
            .border-block__img-block {
              flex: 0 0 100%;
              max-width: 100%;
              .border-block__img {
                position: relative;
                z-index: 2;
                justify-content: flex-start;
                .border-block__price {
                  right: -10px;
                  top: -41px;
                }
              }
            }
            .border-block__addon-block {
              flex: 0 0 100%;
              max-width: 100%;
              padding-left: 15px;
              .panel {
                .panel__header {
                  .MuiGrid-item {
                    align-items: flex-start;
                    justify-content: flex-start;
                  }
                }
                .panel__content-row {
                  .MuiFormControlLabel-root {
                    position: relative;
                    z-index: 4;
                  }

                  .MuiGrid-root {
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding-left: 0;
                  }
                }
              }
            }
          }
          max-width: 400px;
          width: 400px;
        }
      }
    }
    .subpackage-alert {
      display: flex;
      .MuiAlert-icon {
        display: none;
      }
    }
    .block-heading {
      @include fontSize-lineHeight(16, 22);
      .block-heading__rgt-col {
        display: none;
      }
    }
    .panel {
      .panel__header {
        strong {
          @include fontSize-lineHeight(15, 20);
        }
      }
    }
    .tabs-panel {
      .panel__content-row {
        @include fontSize-lineHeight(14, 20);
      }
    }
  }
}
@media screen and (max-width: 650px) {
  body {
    .radio-block {
      .border-block {
        &.border-block--fixed-width {
          &.border-block--pkg-addon {
            max-width: 100%;
          }
          max-width: 300px;
          width: 100%;
        }
      }
    }
    .modal {
      .modal-lcol.modal-lcol--mt {
        padding: 0 0 30px;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .MuiStepLabel-iconContainer {
    div {
      width: 35px;
      height: 35px;
      svg {
        font-size: 1.2em;
      }
    }
  }
  .MuiStepLabel-labelContainer {
    display: none;
  }
  .MuiStepConnector-root {
    &.MuiStepConnector-horizontal {
      &.MuiStepConnector-alternativeLabel {
        top: 18px;
      }
    }
  }
  .panel {
    .panel__header {
      flex-direction: column;
    }
    .panel__content-row {
      flex-direction: column;
    }
  }
  body {
    .overlay-outer {
      .accordion-panel--padding-sm {
        .panel {
          .panel__content-row {
            flex-direction: column !important;
            .MuiGrid-grid-md-5 {
              max-width: 100%;
              flex-basis: 100%;
            }
            .MuiGrid-grid-md-7 {
              max-width: 100%;
              flex-basis: 100%;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 380px) {
  body {
    .accordion-headerrow--iconbutton {
      p {
        margin: 0;
        &:last-child {
          margin-right: 0em;
        }
      }
      .accordion-headerrow__edit {
        width: 25px;
        height: 25px;
        svg {
          font-size: 0.8em;
        }
      }
      .accordion-headerrow__delete {
        img {
          width: 10px;
        }
      }
    }
  }
}
