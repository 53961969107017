$black: #000000;
$white: #ffffff;
$textPrimary: #243746;
$darkGrey: #424242;
$textMediumGrey: #737e87;
$textGreyDarker: #5c676d;
$textLink: #4c90c5;
$darkBlue: #003348;
$darkerBlue: #012c3e;
$lightBlue: #34adec;
$sidebarLinkBg: #103c6e;
$sidebarLinkHoverColor: #54bff7;
$inputOutlinedFocus: #0e6ec2;
$inputRequired: #e53935;
$fontFamily: 'Lato', sans-serif;
$borderLighterColor: #dfe4e9;
$borderLight: #d4dfe6;
$adminModalFooterBg: #e0e8ef;
$btnPrimary: #0e6ec2;
$btnCancelBorder: #b9c3cc;
$btnCancelColor: #555d77;
$lightGrey: #bfc5d6;
$lighterGrey: #aeb2c1;
$headingColor: #013448;
$headingInnerColor: #045d80;
$tableHeaderBg: #e0e8ef;
$tableHeaderColor: #636f74;
$tableCellColor: #5c676d;
$paginationArrowBg: #e4e9e9;
$backBtnBg: #5e78ae;
$backBtnBgHover: #4c669c;
$tabBgColor: #d2dae3;
$tabHeaderColor: #82888e;
$panelBg: #eff9ff;
$btnDelete: #e12d39;
$btnDeleteHover: #c51f2a;
$colorDanger: #e53935;
$modalHeadingColor: #012310;
$inputBorder: #c4c4c4;
$darkBlueBtn: #1f4690;
$badgeSeasonalBg: #d7efeb;
$badgeSeasonalColor: #4b6b58;
$badgeSeasonalBorder: #c6e5e0;
$badgeGreenBg: #b8f0b8;
$badgeGreenColor: #10480c;
$placeholderColor: #a2a2a2;
$labelHeadingColor: #26343b;
$labelDescColor: #5c676d;
$labelBg: #f6f6f6;
$labelBorderColor: #bbbbbb;
$linkColor: #094d9b;
$inputIconColor: #9c9c9c;
$prevBtnBg: #a9b4bd;
$prevBtnColor: #555d77;
$lightGreyBoder: #e3e3e3;
$rowBgColor: #f6f6f6;
$lightGreyBorder: #dbdbdb;
$greyBlockBorder: #ececec;
$cardIconColor: #b5c0c9;
$badgeRedBg: #ffd9d1;
$badgeRedColor: #360e07;
$badgeBlueBg: #d1e5ff;
$badgeBlueColor: #071b36;
$orangeColor: #e6880c;
$greyColor: #333d43;
$lightRedBg: #f2e1e1;
$lightRedBadgeBg: #f8ad9e;
$greyBg: #f1f1f1;
$previousBtn: #b5c4d1;
$previousBtnColor: #798895;
$nextBtn: #798895;
$inputDisabledBg: #e6e6e6;
$inputDisabledBorder: #b1b1b1;
$badgePurpleBg: #b8c9f0;
$badgePurpleColor: #3c475e;
$warningBgColor: #fff4e5;
$customPanelBg: #f8f9fb;
$customPanelBorderColor: #e1eaee;
$customPanelLabelBg: #edeff5;
$customPanelLabelBorder: #bec2cf;
$customPanelSubHeading: #475257;
$cardBgBlue: #34adec;
$cardBgDarkBlue: #0e6ec2;
$cardBgPurple: #5e78ae;
$copyrightText: #687986;
$badgeYellowBg: #fff3cd;
$badgeYellowColor: #856404;
$summerSeason: #ff7240;
$summerSeasonHover: #d6592d;
