@mixin font-size($sizeValue: 16) {
  font-size: $sizeValue + px; //fallback for old browsers
  font-size: (0.0625 * $sizeValue) + rem;
}

@mixin line-height($heightValue: 28) {
  line-height: $heightValue + px; //fallback for old browsers
  line-height: (0.0625 * $heightValue) + rem;
}

@mixin fontSize-lineHeight($sizeValue: 18, $heightValue: 24) {
  @include font-size($sizeValue);
  @include line-height($heightValue);
}

@mixin box-shadow(
  $x-axis: 0,
  $y-axis: 1px,
  $blur: 2px,
  $color: rgba(0, 0, 0, 0.5),
  $inset: false
) {
  @if $inset {
    -webkit-box-shadow: inset $x-axis $y-axis $blur $color;
    -moz-box-shadow: inset $x-axis $y-axis $blur $color;
    box-shadow: inset $x-axis $y-axis $blur $color;
  } @else {
    -webkit-box-shadow: $x-axis $y-axis $blur $color;
    -moz-box-shadow: $x-axis $y-axis $blur $color;
    box-shadow: $x-axis $y-axis $blur $color;
  }
}

@mixin border-radius($radius) {
  border-radius: $radius + px;
  -webkit-border-radius: $radius + px;
  -moz-border-radius: $radius + px;
}
