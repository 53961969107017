.page-loader {
  position: fixed;
  z-index: 9999999;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .loader-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 6px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .loader-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .loader-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .loader-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
