@import "../../Variables.scss";
@import "../../Mixin.scss";
body{
    .confirmation-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 25px 45px;
      max-width: 500px;
      .confirmation-box__circle {      
        color: $colorDanger;       
        display: inline-flex;
        justify-content: center;
        align-items: center;
        @include fontSize-lineHeight(75, 75);  
        &.confirmation-box__circle--success{
          color: #148703;
        }
      }
      .confirmation-box__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        h1 {
          @include fontSize-lineHeight(30, 36);
          color: $modalHeadingColor;
          font-family: $fontFamily;
          margin: 10px 0 22px 0;
          font-weight: 700;
        }
        p {
          @include fontSize-lineHeight(17, 26);
          color: $darkGrey;
          font-family: $fontFamily;
          margin-bottom: 20px;
          text-align: center;
        }
      }
      .confirmation-box__label{
        color: $modalHeadingColor;
        @include fontSize-lineHeight(17, 26);
        font-weight: 700;
        align-self: flex-start;
      }
      .confirmation-box__footer {
        margin-top: 30px;
        padding: 0;
        .footer__btn {
          min-width: 126px;
          margin-left: 0;
          font-family: $fontFamily;
          @include fontSize-lineHeight(17, 26);
          &:first-of-type {
            margin-right: 18px;
          }
        }
      }
    }
}
@media only screen and (max-width: 767px) {
body{
  .confirmation-box{
    padding:20px;
    .confirmation-box__content {
      h1{
        @include fontSize-lineHeight(22, 28);
        margin: 10px 0 15px 0;
      }
      p{
        @include fontSize-lineHeight(15, 23);
      }
    }
    .confirmation-box__label{
      @include fontSize-lineHeight(15, 23);
    }
    .confirmation-box__footer {   
      .footer__btn{
        min-width: 80px;
      }
    }
  }
}
}