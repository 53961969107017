@import '../../components/Variables.scss';
@import '../../components/Mixin.scss';

html {
  body,
  input,
  .MuiInputBase-root,
  .MuiInputLabel-outlined {
    font-family: $fontFamily;
    @include fontSize-lineHeight(16, 18);
  }
  .reg-bg {
    background: url(../../assets/login-banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    flex-basis: 60%;
    max-width: 60%;
    &:before {
      background: rgba(0, 0, 0, 0.3);
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
  .reg-wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-basis: 40%;
    max-width: 40%;
    .reg-wrap__reg-inner {
      height: calc(100vh - 80px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 520px;
      padding: 10px 20px;
      min-width: 520px;
      .reg-inner__logo {
        width: 307px;
        display: flex;
        align-self: center;
        margin-bottom: 3.5em;
      }
    }
    .reg-inner__heading {
      @include fontSize-lineHeight(30, 39);
      font-family: $fontFamily;
      font-weight: 900;
      position: relative;
      color: $textPrimary;
      &.reg-inner__heading--mb {
        margin-bottom: 1.7em;
      }
      &.reg-inner__heading--brdr {
        &:before {
          content: '';
          width: 156px;
        }
      }
      &:before {
        content: '';
        position: absolute;
        width: 230px;
        height: 5px;
        background: $textPrimary;
        bottom: -15px;
        border-radius: 5px;
      }
    }
    .reg-inner__subheading {
      color: $textMediumGrey;
      @include fontSize-lineHeight(22, 27);
      margin: 1.2em 0 1em;
    }
    .reg-inner__btn {
      width: 100%;
      padding: 20px 10px;
      text-align: center;
      background: $darkBlue;
      color: $white;
      margin-top: 2.8em;
      text-transform: capitalize;
      font-family: $fontFamily;
      @include fontSize-lineHeight(18, 24);
      &:hover {
        background: $darkerBlue;
      }
    }
    .reg-inner__row {
      margin-top: 19px;
    }
    .reg-inner__input {
      margin: 25px 0 0;
    }
    .reg-inner__link {
      @include fontSize-lineHeight(18, 22);
      color: $textLink;
      text-decoration: none;
      display: inline-flex;
      &:hover {
        text-decoration: underline;
      }
    }
    .reg-inner__check {
      color: $textMediumGrey;
      @include fontSize-lineHeight(18, 22);
      .MuiTypography-root {
        @include fontSize-lineHeight(18, 22);
      }
    }
  }
  .copyright {
    color: $textMediumGrey;
    @include fontSize-lineHeight(16, 22);
    display: flex;
    align-self: center;
    padding: 0 20px;
    flex-direction: column;
    width: 520px;
  }
  .MuiOutlinedInput-root.Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border-color: $inputOutlinedFocus;
    }
    &:hover {
      border-color: $inputOutlinedFocus;
    }
  }
  .MuiInputLabel-outlined {
    color: #bcbcbc;
    &.MuiInputLabel-shrink {
      color: $inputOutlinedFocus;
      .MuiFormLabel-asterisk {
        color: $inputRequired;
      }
    }
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $darkBlue;
  }

  .MuiInputLabel-root.Mui-focused {
    color: $inputOutlinedFocus;
  }
}
.error {
  color: $inputRequired;
  @include fontSize-lineHeight(14, 20);
}
@media only screen and (min-width: 1800px) {
  html {
    .reg-bg {
      flex-basis: 70%;
      max-width: 70%;
    }
    .reg-wrap {
      flex-basis: 30%;
      max-width: 30%;
    }
  }
}
@media only screen and (max-width: 1500px) {
  html {
    .reg-wrap {
      .reg-wrap__reg-inner {
        min-width: 480px;
      }
    }
  }
}
@media only screen and (max-width: 1400px) {
  html {
    .reg-bg {
      flex-basis: 60%;
      max-width: 60%;
    }
    .reg-wrap {
      flex-basis: 40%;
      max-width: 40%;
      .reg-inner__row {
        margin-top: 12px;
      }
      .reg-wrap__reg-inner {
        height: calc(100vh - 60px);
        min-width: 100%;
        padding: 0 60px;
        .reg-inner__logo {
          width: 260px;
          margin-bottom: 2em;
        }
        .reg-inner__heading {
          @include fontSize-lineHeight(26, 32);
          &:before {
            width: 135px;
          }
        }
        .reg-inner__subheading {
          @include fontSize-lineHeight(20, 26);
          margin: 1em 0 0.8em;
        }
        .reg-inner__input {
          margin: 20px 0 12px;
        }
        .MuiInputLabel-outlined {
          @include fontSize-lineHeight(15, 18);
        }
        .reg-inner__btn {
          padding: 9px 10px;
          margin-top: 1.5em;
        }
        .reg-inner__link {
          @include fontSize-lineHeight(16, 22);
        }
        .reg-inner__check {
          color: $textMediumGrey;
          @include fontSize-lineHeight(16, 22);
          .MuiTypography-root {
            @include fontSize-lineHeight(16, 22);
          }
        }
      }
    }
    .copyright {
      @include fontSize-lineHeight(14, 20);
      padding: 0 60px;
      width: 100%;
    }
  }
}
@media only screen and (max-width: 1150px) {
  html {
    .reg-bg {
      flex-basis: 52%;
      max-width: 52%;
    }
    .reg-wrap {
      flex-basis: 48%;
      max-width: 48%;
      .reg-inner__heading {
        @include fontSize-lineHeight(28, 36);
      }
    }
    .copyright {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 950px) {
  html {
    .reg-bg {
      display: none;
      &.reg-bg--forgot {
        display: none;
      }
      .reg-bg__wrap {
        display: none;
      }
    }
    .reg-wrap {
      flex-basis: 100%;
      max-width: 100%;
      .reg-wrap__reg-inner {
        min-width: 520px;
      }
      .reg-inner__heading {
        @include fontSize-lineHeight(22, 30);
      }
      .reg-inner__subheading {
        @include fontSize-lineHeight(17, 22);
      }
      .reg-inner__link {
        @include fontSize-lineHeight(15, 20);
      }
      .reg-inner__btn {
        padding: 13px 10px;
        margin-top: 2.5em;
      }
      .copyright {
        @include fontSize-lineHeight(14, 20);
        width: 520px;
      }
      .reg-inner__check {
        .MuiFormControlLabel-label {
          @include fontSize-lineHeight(15, 20);
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  html {
    .reg-bg {
      display: none;
    }
    .reg-wrap {
      flex-basis: 100%;
      max-width: 100%;
      .reg-wrap__reg-inner {
        padding: 10px 12px;
        height: 100%;
        .reg-inner__heading {
          @include fontSize-lineHeight(19, 27);
          &.reg-inner__heading--brdr {
            &:before {
              content: '';
              width: 97px;
            }
          }
          &:before {
            bottom: -11px;
          }
        }
        .reg-inner__subheading {
          @include fontSize-lineHeight(15, 22);
        }
        .reg-inner__btn {
          padding: 12px 10px;
          margin-top: 2.5em;
        }
      }
      .copyright {
        width: 520px;
        margin: 50px 0 25px;
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  html {
    .reg-wrap {
      max-width: 100%;
      .reg-wrap__reg-inner {
        padding: 35px 20px;
        height: 100%;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
      }
      .copyright {
        width: 100%;
        margin: 50px 0 25px;
        text-align: center;
      }
    }
  }
}
